import { SellingMethodEnum } from '@bit-ui-libs/common'
import { Disclosure, Tab } from '@headlessui/react'
import { t } from 'i18next'
import { isUndefined } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import Zoom from 'react-medium-image-zoom'
import { useNavigate } from 'react-router-dom'

import { Button, Icon, Input } from '@/common/components'
import { useNotify } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import { ConditionalRendering } from '@/components'
import type { CustomerAssetWithPrice } from '@/core'
import { ListingService, classNames, dateToStringFormat } from '@/core'
import { EventService } from '@/core/events'
import { colors } from '@/theme'

import { What, WhatFull } from '../what'
import { When, WhenFull } from '../when'
import { Where, WhereFull } from '../where'
import { Who, WhoFull } from '../who'
import 'react-medium-image-zoom/dist/styles.css'

import style from './index.module.css'

import { FavoriteButton } from '.'

export enum EAuctionStatus {
  BidAccepted = 'BID_ACCEPTED',
  Available = 'AVAILABLE',
}

export interface ProductInformationProps {
  product: CustomerAssetWithPrice
  listingId?: string
  isLogged?: boolean
  showTags?: boolean
}
export const ProductInformation: React.FC<ProductInformationProps> = ({
  product,
  listingId,
  isLogged,
  showTags,
}) => {
  const [fixedPrice, setFixedPrice] = useState<string>(product.price)
  const [priceError, setPriceError] = useState<string>()
  const [priceBidAmount, setPriceBidAmount] = useState<number>(0)
  const [currentHigherPrice, setCurrentHigherPrice] = useState<number>(0)
  const [showFullWhere, setShowFullWhere] = useState<boolean>(false)
  const [showFullWhat, setShowFullWhat] = useState<boolean>(false)
  const [showFullWhen, setShowFullWhen] = useState<boolean>(false)
  const [showFullWho, setShowFullWho] = useState<boolean>(false)

  const { success, error } = useNotify()

  const returnToList = () => {
    history.back()
  }

  const categories = useMemo(() => {
    return product?.serializedCategories?.join(' | ') || ''
  }, [product])

  const navigate = useNavigate()

  const rendererAuctionTimer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: any) => {
    if (completed) {
      // Render a completed state
      return <span>{t(i18nKeys.bid.auction.timeOver)}</span>
    } else {
      // Render a countdown
      return (
        <>
          <div className="grid">
            <span>{t(i18nKeys.ui.remaining)}:</span>
            <span className="mb-4">
              {zeroPad(days)} {t(i18nKeys.bid.auction.days)} - {zeroPad(hours)}:
              {zeroPad(minutes)}:{zeroPad(seconds)}
            </span>
            <div className="mb-4">
              <Input
                type="number"
                name="bidPrice"
                id="bidPrice"
                placeholder={'Amount'}
                onChange={handleInputChange}
                error={priceError}
                defaultValue={0}
              />
            </div>
          </div>
          <Button
            type="button"
            className="flex items-center justify-center rounded-md px-3 py-3 w-full"
            color={colors.linkGreen}
            onClick={async () => onPlaceNewBid(priceBidAmount)}
            disabled={priceError !== undefined || !priceBidAmount}
          >
            {t(i18nKeys.profile.myLibrary.productInfo.placeBid)}
          </Button>
        </>
      )
    }
  }

  const rendererDutchTimer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: any) => {
    if (completed) {
      // Render a completed state
      return <span>{t(i18nKeys.bid.auction.timeOver)}</span>
    } else {
      // Render a countdown
      if (product.auctionStatus === EAuctionStatus.Available) {
        return (
          <>
            <div className="grid">
              <span>{t(i18nKeys.ui.remaining)}:</span>
              <span className="mb-4">
                {zeroPad(days)} {t(i18nKeys.bid.auction.days)} -{' '}
                {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
              </span>
              <div className="mb-4">
                <Input
                  type="number"
                  name="bidPrice"
                  id="bidPrice"
                  placeholder={'Amount'}
                  onChange={handleInputChange}
                  error={priceError}
                  defaultValue={0}
                />
              </div>
            </div>
            <Button
              type="button"
              className="flex items-center justify-center rounded-md px-3 py-3 w-full"
              color={colors.linkGreen}
              onClick={async () => onPlaceNewBid(priceBidAmount)}
              disabled={priceError !== undefined}
            >
              {t(i18nKeys.profile.myLibrary.productInfo.placeBid)}
            </Button>
          </>
        )
      } else {
        return (
          <div
            className="w-full text-center bg-orange-100 border-orange-500 rounded-b text-orange-900 px-4 py-3 shadow-md"
            role="alert"
          >
            <span>{t(i18nKeys.bid.auction.paused)}</span>
          </div>
        )
      }
    }
  }

  const redirectToBuyProcess = () => {
    navigate(`/buyprocess/${listingId}`)
  }

  const onPlaceNewBid = async (bidPrice: number) => {
    try {
      await ListingService.placeNewBid({
        listingId: listingId!,
        price: bidPrice,
      })
      success('Your bid was placed.')
    } catch (e: any) {
      error(e.response.data.message)
    }
    // TODO: Add translations
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (
      parseFloat(event.target.value) <= currentHigherPrice &&
      product.sellingMethod === SellingMethodEnum.Auction
    ) {
      setPriceError('The amount must be greater than the current price')
      // TODO: add tranlates and get the text
    } else {
      setPriceError(undefined)
    }

    setPriceBidAmount(parseFloat(event.target.value))
  }

  const fetchPrice = async () => {
    const listing = await ListingService.getListingByAssetId(product.id)
    setFixedPrice(listing.price as any)
  }

  useEffect(() => {
    if (
      product.sellingMethod === SellingMethodEnum.Auction ||
      product.sellingMethod === SellingMethodEnum.Dutch
    ) {
      getLastBid()
    } else if (
      product.sellingMethod === SellingMethodEnum.Fixed &&
      !product.price
    ) {
      fetchPrice()
    }
  }, [product, listingId])

  const getLastBid = async () => {
    if (!listingId) {
      setCurrentHigherPrice(parseFloat(product.price))
      return
    }

    try {
      const lastBid = await ListingService.getLastBid(listingId)
      setCurrentHigherPrice(lastBid.price)
    } catch {
      setCurrentHigherPrice(parseFloat(product.price))
    }
  }

  let actionSectionWithButton = null

  switch (product?.sellingMethod) {
    case SellingMethodEnum.Auction:
      actionSectionWithButton = (
        <div className="bg-white rounded mb-5 h-full p-5 flex flex-col items-center text-center">
          <label className="w-full text-xl font-semibold">
            {t(i18nKeys.bid.auction.title)}
          </label>
          <p className="text-gray-500 my-3">
            {t(i18nKeys.bid.auction.subtitle)}
          </p>
          <div className="my-3">
            <p className="text-3xl tracking-tight text-gray-900 text-center">
              ${currentHigherPrice}
            </p>
          </div>
          <div className="text-center font-semibold my-3">
            <Countdown
              date={Date.parse(product.endAt)}
              renderer={rendererAuctionTimer}
              daysInHours={true}
            />
          </div>
        </div>
      )
      break

    case SellingMethodEnum.Dutch:
      actionSectionWithButton = (
        <div className="bg-white rounded mb-5 h-fit p-5 flex flex-col items-center text-center h-full">
          <label className="w-full text-xl font-semibold">
            {t(i18nKeys.bid.dutchauction.title)}
          </label>
          <p className="text-gray-500 my-3">
            {t(i18nKeys.bid.dutchauction.body)}
          </p>
          <div className="my-3">
            <p className="text-3xl tracking-tight text-gray-900 text-center">
              ${product.price}
            </p>
          </div>
          <div className="text-center font-semibold my-3">
            <Countdown
              date={Date.parse(product.endAt)}
              renderer={rendererDutchTimer}
              daysInHours={true}
            />
          </div>
        </div>
      )
      break

    case SellingMethodEnum.Fixed:
      actionSectionWithButton = (
        <div className="bg-white rounded mb-5 h-fit p-5 flex flex-col items-center text-center h-full">
          <span className="w-full text-xl font-semibold">Fixed Price Item</span>
          <p className="text-gray-500 my-3">
            {t(i18nKeys.profile.myLibrary.productInfo.onSaleAsset)}
          </p>

          <div className="my-3">
            <p className="text-3xl tracking-tight text-gray-900 text-center">
              ${fixedPrice}
            </p>
          </div>

          <Button
            type="button"
            className="flex items-center justify-center rounded-md px-3 py-3 w-full"
            color={colors.linkGreen}
            onClick={() => redirectToBuyProcess()}
          >
            {t(i18nKeys.profile.myLibrary.productInfo.buyProduct)}
          </Button>
        </div>
      )
      break

    case SellingMethodEnum.Inverse:
      actionSectionWithButton = (
        <div className="bg-white rounded mb-5 h-fit p-5 h-full">
          <label className="w-full text-xl font-semibold">
            {t(i18nKeys.profile.myLibrary.title)}
          </label>
          <p className="text-gray-500 my-3">
            {t(i18nKeys.profile.myLibrary.productInfo.publishAsset)}
          </p>
          <div className="my-3">
            <p className="text-3xl tracking-tight text-gray-900 text-center">
              ${currentHigherPrice}
            </p>
          </div>
          <div className="text-center font-semibold my-3">
            <Countdown date={Date.now() + 5000} renderer={rendererDutchTimer} />
            <Button
              type="button"
              className="flex items-center justify-center rounded-md px-3 py-3 w-full"
              color={colors.linkGreen}
              onClick={async () => onPlaceNewBid(priceBidAmount)}
              disabled={priceError !== undefined || !priceBidAmount}
            >
              {t(i18nKeys.profile.myLibrary.productInfo.placeBid)}
            </Button>
          </div>
        </div>
      )
      break

    default:
      actionSectionWithButton = (
        <div className="bg-white rounded mb-5 h-fit p-5 h-full">
          <label className="w-full text-xl font-semibold">
            {t(i18nKeys.profile.myLibrary.title)}
          </label>
          <p className="text-gray-500 my-3">
            {t(i18nKeys.profile.myLibrary.productInfo.publishAsset)}
          </p>
          <Button
            type="button"
            className="flex items-center justify-center rounded-md px-3 py-3 w-full"
            color={colors.gray[400]}
          >
            {t(i18nKeys.profile.myLibrary.productInfo.sellAsset)}
          </Button>
        </div>
      )
      break
  }

  let bodyContent = null

  if (showFullWhere) {
    bodyContent = (
      <WhereFull
        eventId={product?.eventIdRef}
        onClickback={() => setShowFullWhere(false)}
      />
    )
  } else if (showFullWhat) {
    bodyContent = (
      <WhatFull product={product} onClickback={() => setShowFullWhat(false)} />
    )
  } else if (showFullWhen) {
    bodyContent = (
      <WhenFull
        mintedAt={product?.mintedAt}
        createdAt={product?.createdAt}
        onClickBack={() => setShowFullWhen(false)}
      />
    )
  } else if (showFullWho) {
    bodyContent = (
      <WhoFull
        witness={product?.witness}
        userId={product?.createdBy}
        onClickBack={() => setShowFullWho(false)}
      />
    )
  } else {
    bodyContent = (
      <>
        <Button
          onClick={() => returnToList()}
          mode="text"
          color={colors.linkGreen}
        >
          {t(i18nKeys.profile.myLibrary.filters.backToList)}
        </Button>

        <div className="flex flex-col sm:grid sm:grid-cols-6 gap-4">
          <div className="bg-white rounded mb-5 col-span-5">
            <div className="mx-auto px-4 py-16 sm:px-6 sm:pt-6 sm:pb-12 lg:px-8">
              <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
                {/* Image gallery */}
                <Tab.Group as="div" className="flex flex-col-reverse">
                  {/* Image selector */}
                  <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
                    <Tab.List className="grid grid-cols-4 gap-6">
                      {product?.serializedImages?.map((image, key) => (
                        <Tab
                          key={`imagesToSelect-${key}`}
                          className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                        >
                          {({ selected }) => (
                            <>
                              <span className="absolute inset-0 overflow-hidden rounded-md">
                                <img
                                  src={EventService.buildDocumentImageUrlById(
                                    image.docId,
                                  )}
                                  alt={image.name ?? ''}
                                  className="h-full w-full object-cover object-center"
                                />
                              </span>
                              <span
                                className={classNames(
                                  selected
                                    ? 'ring-primary-500'
                                    : 'ring-transparent',
                                  'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2',
                                )}
                                aria-hidden="true"
                              />
                            </>
                          )}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>

                  <Tab.Panels>
                    {product?.serializedImages?.map((image, key) => (
                      <Tab.Panel
                        key={`selected-${key}`}
                        className="flex justify-center"
                      >
                        <Zoom>
                          <div className={style.imageWrapper}>
                            <img
                              src={EventService.buildDocumentImageUrlById(
                                image.docId,
                              )}
                              alt={image.name ?? 'Not found'}
                              className="h-full w-full object-cover object-center sm:rounded-lg"
                            />
                          </div>
                        </Zoom>
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>

                {/*  Product info  */}
                <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
                  <div className="flex justify-between">
                    <span>{categories}</span>
                    <div className="flex items-center gap-2">
                      <FavoriteButton
                        assetId={product.assetId}
                        visible={isLogged}
                        iconClassName="fill-primary"
                      />
                      <span className="border p-2">{product?.level}</span>
                    </div>
                  </div>
                  <h1 className="text-xl sm:text-3xl font-bold tracking-tight text-gray-900">
                    {product?.name}
                  </h1>

                  <div className="grid grid-cols-3 gap-2 mt-6">
                    <div className="flex">
                      <Icon className="my-auto" icon="calendar" size={16} />{' '}
                      <span className="font-semibold my-auto pl-1 text-xs">
                        {dateToStringFormat(product?.createdAt)}
                      </span>
                    </div>

                    {(!product?.isVerifiedByWitness &&
                      !product?.isVerifiedByOfficial) ?? (
                      <div className="flex">
                        <Icon
                          icon="checkCircle"
                          className="my-auto"
                          size={16}
                        />{' '}
                        <span className="font-semibold my-auto pl-1 text-xs">
                          {t(
                            i18nKeys.profile.myLibrary.productInfo.selfVerified,
                          )}
                        </span>
                      </div>
                    )}

                    {product?.isMinted ?? (
                      <div className="flex">
                        <Icon icon="paperClip" className="my-auto" size={16} />{' '}
                        <span className="font-semibold my-auto pl-1 text-xs">
                          {t(i18nKeys.common.vdtMinted)}
                        </span>
                      </div>
                    )}
                  </div>
                  <section aria-labelledby="details-heading" className="mt-6">
                    <div className="divide-y divide-gray-200 border-t">
                      <Disclosure as="div">
                        {({ open }) => (
                          <>
                            <h3>
                              <Disclosure.Button className="group relative flex w-full items-center justify-between py-6 text-left">
                                <span
                                  className={classNames(
                                    open ? 'text-primary-600' : 'text-gray-900',
                                    'text-sm font-medium',
                                  )}
                                >
                                  {t(
                                    i18nKeys.sell.nav.preview.product?.features,
                                  )}
                                </span>
                                <span className="ml-6 flex items-center">
                                  {open ? (
                                    <Icon
                                      icon="minus"
                                      color={colors.primary.DEFAULT}
                                      size={20}
                                    />
                                  ) : (
                                    <Icon
                                      icon="add"
                                      color={colors.gray[400]}
                                      size={20}
                                    />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel
                              as="div"
                              className="prose prose-sm pb-6"
                            >
                              <div className="grid grid-cols-2 gap-3">
                                {product?.serializedProps?.map((e) => (
                                  <>
                                    <label
                                      key={e.id + '-key'}
                                      className="font-semibold truncate ..."
                                      title={e.name}
                                    >
                                      {' '}
                                      {e.name}{' '}
                                    </label>
                                    <label
                                      key={e.id + '-value'}
                                      className="truncate ..."
                                      title={e.value}
                                    >
                                      {' '}
                                      {e.value}
                                    </label>
                                  </>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                    {showTags ? (
                      <div className="divide-y divide-gray-200 border-t">
                        <Disclosure as="div">
                          {({ open }) => (
                            <>
                              <h3>
                                <Disclosure.Button className="group relative flex w-full items-center justify-between py-6 text-left">
                                  <span
                                    className={classNames(
                                      open
                                        ? 'text-primary-600'
                                        : 'text-gray-900',
                                      'text-sm font-medium',
                                    )}
                                  >
                                    Tags
                                  </span>
                                  <span className="ml-6 flex items-center">
                                    {open ? (
                                      <Icon
                                        icon="minus"
                                        color={colors.primary.DEFAULT}
                                        size={20}
                                      />
                                    ) : (
                                      <Icon
                                        icon="add"
                                        color={colors.gray[400]}
                                        size={20}
                                      />
                                    )}
                                  </span>
                                </Disclosure.Button>
                              </h3>
                              <Disclosure.Panel
                                as="div"
                                className="prose prose-sm pb-6"
                              >
                                <div className="flex flex-row flex-wrap gap-3">
                                  {product?.tags?.length ? (
                                    product?.tags?.map((tag, tagKey) => (
                                      <span
                                        key={tagKey}
                                        className="inline-flex items-center gap-x-0.5 rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
                                      >
                                        {tag}
                                      </span>
                                    ))
                                  ) : (
                                    <span>No tags</span>
                                  )}
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      </div>
                    ) : null}
                  </section>
                </div>
              </div>
            </div>
          </div>
          <div className="h-full pb-5">{actionSectionWithButton}</div>
        </div>

        <ConditionalRendering
          renderIf={!isUndefined(product) && product.isMinted}
        >
          <div className="mx-auto py-8 sm:py-8 flex flex-col sm:grid sm:grid-cols-2 gap-4">
            <Where
              eventId={product?.eventIdRef}
              onClickAudit={() => setShowFullWhere(true)}
            />
            <What
              product={product}
              onClickAudit={() => setShowFullWhat(true)}
            />
            <When
              mintedAt={product?.mintedAt}
              createdAt={product?.createdAt}
              onClickAudit={() => setShowFullWhen(true)}
            />
            <Who
              userId={product?.createdBy}
              onClickAudit={() => setShowFullWho(true)}
            />
          </div>
        </ConditionalRendering>
      </>
    )
  }

  return <div className="sm:px-12">{bodyContent}</div>
}
