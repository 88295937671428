import React from 'react'

import { Input } from '@/common/components'

import { SellPage, PageActionButtons } from '..'
import { useServiceListing } from '../context/service-listing-context'

export default function ServiceLocationPage() {
  const location = useServiceListing((state) => state.location)
  const update = useServiceListing((state) => state.update)

  return (
    <SellPage title="Specify the location where the service will be offered (city or region).">
      <Input
        id="location"
        name="location"
        label="Location"
        placeholder="City or region"
        onChange={(e) => update('location', e.target.value)}
        value={location}
        className="w-full max-w-lg mt-6"
        maxLength={255}
      />
      <PageActionButtons />
    </SellPage>
  )
}
