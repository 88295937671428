import type { DeviceVdt, UserVdt } from '@bit-ui-libs/common'
import {
  WalletPlatformEnum,
  Web3Service as CommonWeb3Service,
  ChainTypeEnum,
} from '@bit-ui-libs/common'
import { AxiosError, HttpStatusCode } from 'axios'

import envVariables from '@/common/envVariables'

import { doGet, doPost } from '..'
import { serviceConfiguration } from '../service-configuration'

const commonWeb3Service = new CommonWeb3Service(serviceConfiguration)

export interface CreateUserVDTOptions {
  deviceId: string
  latitude: number
  longitude: number
  meanSeaLevel: number
  // wallet address
  recipientAddress: string
}

export interface GetUserVDTOptions {
  userId: string
}

export class Web3Service {
  static async initUserVdt(options: CreateUserVDTOptions): Promise<void> {
    const appName = envVariables.APP_NAME
    const platform = WalletPlatformEnum.Polygon

    return commonWeb3Service.initUserVdt({
      appName: appName,
      deviceId: options.deviceId,
      latitude: options.latitude ?? 0,
      longitude: options.longitude ?? 0,
      meanSeaLevel: options.meanSeaLevel ?? 0,
      platform: platform,
      recipientAddress: options.recipientAddress,
    })
  }

  static async getUserVDT(options: GetUserVDTOptions): Promise<UserVdt | null> {
    try {
      return commonWeb3Service.getUserVdt(options.userId)
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === HttpStatusCode.NotFound) {
          return null
        }
      }

      throw error
    }
  }

  async mint(walletAddress: string, eventId: string) {
    return commonWeb3Service.mint(ChainTypeEnum.Objects, {
      recipientAddress: walletAddress,
      eventId,
      platform: WalletPlatformEnum.Polygon,
    })
  }

  async mintService(
    walletAddress: string,
    eventId: string,
    metadataUrl: string,
  ) {
    return doPost('/web3/v1/nft/mint-service', {
      recipientAddress: walletAddress,
      eventId,
      platform: WalletPlatformEnum.Polygon,
      metadataUrl,
    })
  }

  static async getDeviceVdt(deviceId: string): Promise<DeviceVdt | null> {
    try {
      const vdt = await commonWeb3Service.getDeviceVdt(deviceId)
      return vdt
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === HttpStatusCode.NotFound) {
          return null
        }
      }

      throw error
    }
  }

  async getUserVdt() {
    return doGet(`web3/v1/nft/user-vdt`)
  }
}
