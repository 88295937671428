import type { Bid, CustomerAsset } from '@bit-ui-libs/common'
import { Menu } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { t } from 'i18next'
import React, { useEffect, useMemo, useState } from 'react'
import Countdown, { zeroPad } from 'react-countdown'

import type { IconSource } from '@/common/components'
import { Icon } from '@/common/components'
import { Identifiers } from '@/common/constants'
import i18nKeys from '@/common/i18nKeys'
import Spinner from '@/components/Spinner'
import { AssetService } from '@/core/assets/asset.service'
import { EventService } from '@/core/events'
import { colors } from '@/theme'

export type ListingStatus =
  | 'MINTED'
  | 'LISTING_STARTED'
  | 'LISTING_FAILED'
  | 'LISTED'
  | 'DELIST_STARTED'
  | 'DELIST_FAILED'
  | 'DELISTED'
  | 'ORDER_STARTED'
  | 'ORDER_COMPLETED'
  | 'ORDER_FAILED'

export const statusMapping: Record<
  ListingStatus,
  { label: string; icon: IconSource; iconColor: string }
> = {
  MINTED: {
    label: i18nKeys.bid.detail.status.minted,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  LISTING_STARTED: {
    label: i18nKeys.bid.detail.status.listing_started,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  LISTING_FAILED: {
    label: i18nKeys.bid.detail.status.listing_failed,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  LISTED: {
    label: i18nKeys.bid.detail.status.listed,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  DELIST_STARTED: {
    label: i18nKeys.bid.detail.status.delist_started,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  DELIST_FAILED: {
    label: i18nKeys.bid.detail.status.delist_failed,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  DELISTED: {
    label: i18nKeys.bid.detail.status.delisted,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  ORDER_STARTED: {
    label: i18nKeys.bid.detail.status.order_started,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  ORDER_COMPLETED: {
    label: i18nKeys.bid.detail.status.order_completed,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
  ORDER_FAILED: {
    label: i18nKeys.bid.detail.status.order_failed,
    icon: 'check',
    iconColor: colors.primary.DEFAULT,
  },
}

export type BidItemProps = {
  bid: Bid
}

export const BidItem: React.FC<BidItemProps> = ({ bid }) => {
  const [asset, setAsset] = useState<CustomerAsset>()

  const handleFetchAsset = async () => {
    const assetService = await AssetService.getById(bid?.asset?.id!)
    setAsset(assetService)
  }

  const imageSrc = useMemo(() => {
    if (!asset) return

    let mainImage = asset.serializedImages.find((image) => image.isMain)

    if (!mainImage) {
      mainImage = asset.serializedImages[0]
    }

    if (!mainImage) return

    return EventService.buildDocumentImageUrlById(mainImage.docId)
  }, [asset])

  const description = useMemo(() => {
    if (!asset) return

    return asset.serializedProps.find(
      (p) => p.name === Identifiers.ASSET_DESCRIPTION_PROP,
    )?.value
  }, [asset])

  const dateCreated = useMemo(() => {
    return new Date(bid?.asset?.createdAt!).toLocaleDateString()
  }, [bid?.asset?.createdAt])

  useEffect(() => {
    handleFetchAsset()
  }, [bid?.asset?.id!])

  const rendererTimer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return <span>{t(i18nKeys.bid.auction.timeOver)}</span>
    } else {
      // Render a countdown
      return (
        <span className="mb-4">
          {zeroPad(days)} {t(i18nKeys.bid.auction.days)} - {zeroPad(hours)}:
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      )
    }
  }

  return (
    <div className="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border">
      <div className="flex items-center border-b border-gray-200 p-4 sm:grid sm:grid-cols-4 sm:gap-x-6 sm:p-6">
        <dl className="grid flex-1 grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-3 lg:col-span-2">
          {/* TODO backend not returning order tracking number */}
          {/* <div>
            <dt className="font-medium text-gray-900">{t(i18nKeys.profile.bid?.asset?.detail.orderNumber)}</dt>
            <dd className="mt-1 text-gray-500">{bid?.asset?.trackingNumber}</dd>
          </div> */}
          <div className="hidden sm:block">
            <dt className="font-medium text-gray-900">
              {t(i18nKeys.bid.detail.datePlaced)}
            </dt>
            <dd className="mt-1 text-gray-500">
              <time dateTime={dateCreated}>{dateCreated}</time>
            </dd>
          </div>
          <div>
            <dt className="font-medium text-gray-900">
              {t(i18nKeys.bid.detail.priceTotal)}
            </dt>
            <dd className="mt-1 font-medium text-gray-900">${bid.price}</dd>
          </div>
        </dl>

        <Menu as="div" className="relative flex justify-end lg:hidden">
          <div className="flex items-center">
            <Menu.Button className="-m-2 flex items-center p-2 text-gray-400 hover:text-gray-500">
              <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
            </Menu.Button>
          </div>
        </Menu>
      </div>

      {/* Products */}
      <div className="p-4 sm:p-6">
        <div className="flex items-center sm:items-start">
          <div className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-lg bg-gray-200 sm:h-40 sm:w-40">
            {imageSrc ? (
              <img
                src={imageSrc}
                alt={bid?.asset?.name}
                className="h-full w-full object-cover object-center"
              />
            ) : (
              <div className="flex items-center justify-center h-full">
                <Spinner />
              </div>
            )}
          </div>
          <div className="ml-6 flex-1 text-sm">
            <h5 className="text-xl font-bold mb-2">{bid?.asset?.name}</h5>
            <div className="font-medium text-gray-900 sm:flex sm:justify-between mb-2">
              <h5>{t(i18nKeys.bid.detail.starting)}</h5>
              <p className="mt-2 sm:mt-0">${bid?.listing?.price}</p>
            </div>
            <div className="font-medium text-gray-900 sm:flex sm:justify-between mb-2">
              <h5>{t(i18nKeys.bid.detail.current)}</h5>
              <p className="mt-2 sm:mt-0">${bid?.price}</p>
            </div>
            <p className="hidden text-gray-500 sm:mt-2 sm:block mb-3">
              {description}
            </p>
            <div className="text-gray-900 sm:flex sm:justify-between mb-2 font-bold text-xl">
              <h5>{t(i18nKeys.ui.remaining)}</h5>
              <Countdown
                date={Date.parse(bid.listing?.endAt!)}
                renderer={rendererTimer}
              />
            </div>
          </div>
        </div>

        <div className="mt-6 sm:flex sm:justify-between">
          <div className="flex items-center text-xl">
            <Icon
              icon={statusMapping[bid?.listing?.status!].icon}
              color={statusMapping[bid?.listing?.status!].iconColor}
              size={20}
            />
            <p className="ml-2 font-medium text-gray-500 text-xl">
              {t(statusMapping[bid?.listing?.status!].label)}
            </p>
          </div>

          <div className="mt-6 flex items-center space-x-4 divide-x divide-gray-200 border-t border-gray-200 pt-4 text-sm font-medium sm:ml-4 sm:mt-0 sm:border-none sm:pt-0">
            <div className="flex flex-1 justify-center">
              <a
                href={`/search/listing/${bid.listingId}/event/${asset?.eventIdRef}/detail`}
                className="whitespace-nowrap text-primary-600 hover:text-primary-500"
              >
                {t(i18nKeys.bid.detail.viewProduct)}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
