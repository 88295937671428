import { isTokenExpired } from '@bit-ui-libs/common'
import type {
  BitAuthClaims,
  UserVdt,
  BuyerProfile,
  SellerProfile,
} from '@bit-ui-libs/common'
import jwtDecode from 'jwt-decode'
import type { PropsWithChildren } from 'react'
import React, { useCallback, useReducer, createContext, useEffect } from 'react'

import { Logger } from '@/config'
import { UserService } from '@/core/users'
import { Web3Service } from '@/core/web3/web3.service'

import { finalizeAuth, refreshToken } from '../../core/auth'
import { AuthService } from '../../core/auth/auth.service'
import ApplicationStorage from '../../core/storage'
import envVariables from '../envVariables'
import type { Address } from '../types'

export interface BitIdType {
  userId: string
  level: string
  updatedAt: string
}
export interface UserProfileType {
  avatarUrl?: string
  beingId?: BitIdType
  username: string
  firstName?: string
  middleName?: string
  lastName?: string
  phone?: string
  phoneVerified: boolean
  privacyAccepted: boolean
  addresses: Address[]
  mainProfileCompleted: boolean
  id: string
  userId: string
  email: string
  createdAt: string
  buyerProfile?: BuyerProfile
  sellerProfile?: SellerProfileWithTaxIdType
  storageType?: string
  walletAddress?: string
}

export interface AuthContextState {
  loading: boolean
  authenticated: boolean
  accessToken: string | null
  refreshToken: string | null
  bitToken: string | null
  username: string | null
  firstName: string | null
  middleName: string | null
  lastName: string | null
  phone: string | null
  addresses: Address[]
  mainProfileCompleted: boolean | null
  userId: string | null
  profile: UserProfileType | null
  profileLoaded: boolean
  userVdt: UserVdt | null
  userVdtLoaded: boolean
  walletAddress: string | null
  email: string | null
  createdAt: string | null
  loadingMainAddress: boolean
  mainAddress: Address | null
  buyerProfile: BuyerProfile | null
  sellerProfile: SellerProfileWithTaxIdType | null
}

export type AuthContextActions = {
  clear: () => void
  exchangeCode: (urlCode: string, urlState: string) => void
  reloadMainAddress: () => void
  removeAddress: (addressId: string) => void
  replaceAddress: (addressId: string, address: Address) => void
  setUserAddresses: (addresses: Address[]) => void
  setUserProfile: (userProfile: UserProfileType) => void
  setWalletAddress: (newWalletAddress: string) => void
  setBuyerProfile: (userProfile: UserProfileType) => void
  setSellerProfile: (userProfile: SellerProfileWithTaxIdType) => void
}

enum AuthActionsTypes {
  ACTION_CLEAR = 'ACTION_CLEAR',
  ACTION_SET_TOKENS = 'SET_TOKENS',
  ACTION_SET_USER_ADDRESSES = 'SET_USER_ADDRESSES',
  ACTION_REPLACE_ADDRESS = 'REPLACE_ADDRESS',
  ACTION_SET_USER_PROFILE = 'SET_USER_PROFILE',
  ACTION_SET_USER_VDT = 'SET_USER_VDT',
  ACTION_SET_USER_VDT_LOADED = 'SET_USER_VDT_LOADED',
  ACTION_SET_MAIN_ADDRESS = 'SET_MAIN_ADDRESS',
  ACTION_SET_WALLET_ADDRESS = 'SET_WALLET_ADDRESS',
  ACTION_SET_BUYER_PROFILE = 'SET_BUYER_PROFILE',
  ACTION_SET_SELLER_PROFILE = 'SET_SELLER_PROFILE',
  ACTION_REMOVE_ADDRESS = 'REMOVE_ADDRESS',
  ACTION_SET_LOADING_MAIN_ADDRESS = 'SET_LOADING_MAIN_ADDRESS',
  ACTION_SET_LOADING = 'SET_LOADING',
}

interface AuthContextAction {
  type: AuthActionsTypes
  payload: any | undefined
}

export interface SellerProfileWithTaxIdType extends SellerProfile {
  taxIdType?: string
}

const initialState: AuthContextState = {
  loading: true,
  authenticated: false,
  accessToken: null,
  refreshToken: null,
  bitToken: null,
  // TODO remove this, is already under the 'profile' key
  username: null,
  // TODO remove this, is already under the 'profile' key
  firstName: null,
  // TODO remove this, is already under the 'profile' key
  middleName: null,
  // TODO remove this, is already under the 'profile' key
  lastName: null,
  // TODO remove this, is already under the 'profile' key
  phone: null,
  // TODO remove this, is already under the 'profile' key
  addresses: [],
  // TODO remove this, is already under the 'profile' key
  mainProfileCompleted: null,
  // TODO remove this, is already under the 'profile' key
  userId: null,
  profile: null,
  profileLoaded: false,
  userVdt: null,
  userVdtLoaded: false,
  walletAddress: null,
  email: null,
  createdAt: null,
  loadingMainAddress: true,
  mainAddress: null,
  buyerProfile: null,
  sellerProfile: null,
}

const actions: AuthContextActions = {
  clear: () => {},
  exchangeCode: (_urlCode, _urlState) => {},
  reloadMainAddress: () => {},
  removeAddress: (_addressId) => {},
  replaceAddress: (_index, _address) => {},
  setUserAddresses: (_addresses) => {},
  setUserProfile: (_userProfile) => {},
  setWalletAddress: (_newWalletAddress) => {},
  setBuyerProfile: (_buyerProfile) => {},
  setSellerProfile: (_sellerProfile) => {},
}

const reducer = (
  state: AuthContextState,
  action: AuthContextAction,
): AuthContextState => {
  const { payload } = action
  switch (action.type) {
    case AuthActionsTypes.ACTION_SET_TOKENS:
      return {
        ...state,
        authenticated: true,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
        bitToken: payload.bitToken,
        loading: false,
      }

    case AuthActionsTypes.ACTION_SET_USER_PROFILE:
      return {
        ...state,
        username: payload.username,
        firstName: payload.firstName,
        middleName: payload.middleName,
        lastName: payload.lastName,
        phone: payload.phone,
        addresses: payload.address,
        mainProfileCompleted: payload.mainProfileCompleted,
        userId: payload.userId,
        profile: payload,
        profileLoaded: true,
      }

    case AuthActionsTypes.ACTION_REPLACE_ADDRESS:
      const newAddresses = [...state.addresses]
      const index = newAddresses.findIndex((e) => e.id === payload.addressId)
      newAddresses[index] = payload.address
      return {
        ...state,
        addresses: newAddresses,
      }

    case AuthActionsTypes.ACTION_SET_USER_ADDRESSES:
      return {
        ...state,
        addresses: payload.map((i: Address) => ({
          ...i,
          addressLine1: i.addressLine1 ?? i.address,
        })),
      }

    case AuthActionsTypes.ACTION_SET_USER_VDT:
      return {
        ...state,
        userVdt: payload,
        userVdtLoaded: true,
        walletAddress: payload.walletAddress,
      }

    case AuthActionsTypes.ACTION_SET_USER_VDT_LOADED:
      return {
        ...state,
        userVdtLoaded: true,
      }

    case AuthActionsTypes.ACTION_CLEAR:
      return {
        ...initialState,
      }

    case AuthActionsTypes.ACTION_SET_MAIN_ADDRESS:
      return {
        ...state,
        mainAddress: payload.address,
      }

    case AuthActionsTypes.ACTION_SET_WALLET_ADDRESS:
      return {
        ...state,
        walletAddress: payload,
      }

    case AuthActionsTypes.ACTION_SET_BUYER_PROFILE:
      return {
        ...state,
        buyerProfile: payload,
      }

    case AuthActionsTypes.ACTION_SET_SELLER_PROFILE:
      return {
        ...state,
        sellerProfile: payload,
      }

    case AuthActionsTypes.ACTION_REMOVE_ADDRESS:
      return {
        ...state,
        addresses: state.addresses.filter((address) => address.id !== payload),
      }

    case AuthActionsTypes.ACTION_SET_LOADING_MAIN_ADDRESS:
      return {
        ...state,
        loadingMainAddress: payload,
      }

    case AuthActionsTypes.ACTION_SET_LOADING:
      return {
        ...state,
        loading: payload,
      }

    default:
      throw new Error()
  }
}

export const authContext = createContext<AuthContextState & AuthContextActions>(
  { ...initialState, ...actions },
)

export const AuthContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const clear = useCallback(() => {
    ApplicationStorage.clearAuthKeys()

    dispatch({
      type: AuthActionsTypes.ACTION_CLEAR,
      payload: undefined,
    })
  }, [])

  const refreshTokens = useCallback((_refreshTokenValue: string) => {
    // TODO refresh tokens
  }, [])

  useEffect(() => {
    // TODO set a timeout to refresh tokens
    const tokens = ApplicationStorage.tokens
    let timeoutSeed: Number | null = null
    if (tokens) {
      if (
        isTokenExpired(tokens.accessToken) ||
        isTokenExpired(tokens.bitToken)
      ) {
        if (isTokenExpired(tokens.refreshToken)) {
          clear()
          return
        } else {
          refreshTokens(tokens.refreshToken)
        }
      }

      dispatch({
        type: AuthActionsTypes.ACTION_SET_TOKENS,
        payload: tokens,
      })

      timeoutSeed = setTimeout(
        () => {
          refreshTokens(tokens.refreshToken)
        },
        1000 * 60 * 5, // TODO adjust timer
        [],
      )
    }

    dispatch({
      type: AuthActionsTypes.ACTION_SET_LOADING,
      payload: false,
    })

    return () => {
      if (timeoutSeed) {
        clearTimeout(Number(timeoutSeed))
      }
    }
  }, [clear])

  const setUserProfile = useCallback(
    (userProfile: UserProfileType) => {
      dispatch({
        type: AuthActionsTypes.ACTION_SET_USER_PROFILE,
        payload: userProfile,
      })
    },
    [dispatch],
  )

  const setBuyerProfile = useCallback(
    (buyerProfile: BuyerProfile) => {
      dispatch({
        type: AuthActionsTypes.ACTION_SET_BUYER_PROFILE,
        payload: buyerProfile,
      })
    },
    [dispatch],
  )

  const setSellerProfile = useCallback(
    (sellerProfile: SellerProfileWithTaxIdType | null) => {
      dispatch({
        type: AuthActionsTypes.ACTION_SET_SELLER_PROFILE,
        payload: sellerProfile,
      })
    },
    [dispatch],
  )

  const reloadMainAddress = useCallback(async (): Promise<Address | null> => {
    dispatch({
      type: AuthActionsTypes.ACTION_SET_LOADING_MAIN_ADDRESS,
      payload: true,
    })

    const authService = new AuthService()
    try {
      const mainAddressResponse = await authService.getMainAddress()
      // TODO we need to check if this property exists in the returned object after "authService.getMainAdress" does not return any
      if (!mainAddressResponse.error) {
        dispatch({
          type: AuthActionsTypes.ACTION_SET_MAIN_ADDRESS,
          payload: { address: mainAddressResponse },
        })
        return mainAddressResponse as Address
      }
    } catch (_addressError) {
      // TODO handle error
      // Do not return from here since new users will not have an address
    } finally {
      dispatch({
        type: AuthActionsTypes.ACTION_SET_LOADING_MAIN_ADDRESS,
        payload: false,
      })
    }

    return null
  }, [dispatch])

  const reloadProfile = useCallback(
    async (bitToken: string) => {
      const bitTokenDecoded = jwtDecode(bitToken) as BitAuthClaims
      const authService = new AuthService()
      const userService = new UserService()
      let userProfile = null
      let buyerProfile = null
      let sellerProfile = null
      let userAddressesList = null
      try {
        userProfile = await authService.getProfile(bitTokenDecoded.userId)
        buyerProfile = await userService.getListUserBuyerProfiles(
          userProfile.id,
        )

        const sellerProfileList = await userService.getListUserSellerProfiles(
          userProfile.id,
        )

        sellerProfile =
          sellerProfileList.find((e) => e.isDefault === true) ?? null

        if (buyerProfile.length === 0) {
          const result = await userService.addBuyerProfile({
            userId: userProfile.id,
            appName: envVariables.APP_NAME,
            isDefault: true,
          })
          buyerProfile.push(result)
        }

        userAddressesList = await userService.getUserAddresses({
          userId: userProfile.id,
        })
      } catch (_profileError) {
        // TODO handle error
        return
      }

      const mainAddress = await reloadMainAddress()
      if (mainAddress) {
        userProfile.address = [mainAddress]
      }

      setUserProfile({
        ...userProfile,
        userId: bitTokenDecoded.userId,
      })
      setBuyerProfile(buyerProfile[0] ?? null)

      setSellerProfile(sellerProfile)

      setUserAddresses(userAddressesList.items)
    },
    [reloadMainAddress, setUserProfile],
  )

  useEffect(() => {
    // Loads user profile and address if bitToken is present. At this point the user exists.
    if (state.bitToken) {
      const expirationDate = ApplicationStorage.expirationDate
        ? new Date(ApplicationStorage.expirationDate)
        : null
      const currentDate = new Date()
      if (expirationDate && expirationDate <= currentDate) {
        refreshToken()
      }
      reloadProfile(state.bitToken)
    }
  }, [state.bitToken, reloadProfile])

  const reloadUserIVDT = useCallback(
    async (bitToken: string) => {
      const bitTokenDecoded = jwtDecode(bitToken) as BitAuthClaims

      try {
        const userVdt = await Web3Service.getUserVDT({
          userId: bitTokenDecoded.userId,
        })

        dispatch({
          type: AuthActionsTypes.ACTION_SET_USER_VDT,
          payload: userVdt,
        })
      } catch (error) {
        Logger.error('Error getting userVdt', undefined, error as Error)
        // TODO do something to norify the user or explain why we are just swallowing this error.
      }

      dispatch({
        type: AuthActionsTypes.ACTION_SET_USER_VDT_LOADED,
        payload: null,
      })
    },
    [dispatch],
  )

  useEffect(() => {
    // Load user iVDT
    if (state.bitToken) {
      reloadUserIVDT(state.bitToken)
    }
  }, [state.bitToken, reloadUserIVDT])

  const exchangeCode = useCallback(
    async (urlCode: string, urlState: string) => {
      // TODO handle error
      const result = await finalizeAuth(urlCode, urlState)

      if (result?.accessToken && result?.bitToken && result?.refreshToken) {
        const d = new Date()
        d.setSeconds(d.getSeconds() + result.expiresIn)

        ApplicationStorage.tokens = {
          accessToken: result.accessToken,
          refreshToken: result.refreshToken,
          bitToken: result.bitToken,
          idToken: result.idToken,
          expirationDate: d.toDateString(),
        }

        dispatch({
          type: AuthActionsTypes.ACTION_SET_TOKENS,
          payload: {
            accessToken: result.accessToken,
            refreshToken: result.refreshToken,
            bitToken: result.bitToken,
          },
        })
      }

      dispatch({
        type: AuthActionsTypes.ACTION_SET_LOADING,
        payload: false,
      })
    },
    [dispatch],
  )

  const replaceAddress = useCallback(
    (addressId: string, address: Address) => {
      dispatch({
        type: AuthActionsTypes.ACTION_REPLACE_ADDRESS,
        payload: {
          addressId,
          address,
        },
      })
    },
    [dispatch],
  )

  const setUserAddresses = useCallback(
    (addresses: Address[]) => {
      dispatch({
        type: AuthActionsTypes.ACTION_SET_USER_ADDRESSES,
        payload: addresses,
      })
    },
    [dispatch],
  )

  const setWalletAddress = useCallback(
    (newWalletAddress: string) => {
      dispatch({
        type: AuthActionsTypes.ACTION_SET_WALLET_ADDRESS,
        payload: newWalletAddress,
      })
    },
    [dispatch],
  )

  const removeAddress = useCallback(
    (addressId: string) => {
      dispatch({
        type: AuthActionsTypes.ACTION_REMOVE_ADDRESS,
        payload: addressId,
      })
    },
    [dispatch],
  )

  const contextValue = {
    ...state,
    clear,
    exchangeCode,
    reloadMainAddress,
    removeAddress,
    replaceAddress,
    setUserAddresses,
    setUserProfile,
    setWalletAddress,
    setBuyerProfile,
    setSellerProfile,
  }

  return (
    <authContext.Provider value={contextValue as any}>
      {children}
    </authContext.Provider>
  )
}
