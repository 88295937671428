import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { BeingIdLevelCard, Icon } from '@/common/components'
import type { TokenizationResponse } from '@/common/components/Paysafe/PaysafeClient'
import { authContext } from '@/common/context/auth_context'
import { useLevelCheck, useNotify } from '@/common/hooks'
import { useOnlyAuthenticated } from '@/common/hooks/use-only-authenticated'
import type { BuyerShippingAddress } from '@/common/types'
import Spinner from '@/components/Spinner'
import { Logger } from '@/config'
import type {
  PaymentDetailsResponse,
  PaymentOrder,
  PaymentOrderBillingAddress,
  PaymentOrderShippingAddress,
  ShippingCostResponseInterface,
  PaymentCard,
} from '@/core'
import { classNames, ListingService, UserService } from '@/core'

import { BuyerAddresses } from './BuyerAddresses'
import { CarrierSelection } from './CarrierSelection'
import { PaymentMethods } from './PaymentMethods'
import { Stepper, stepperProcess } from './Stepper'
import { Summary } from './Summary'
import { TotalCard } from './TotalCard'

export type PaymentHandleToken = {
  profileHandleToken: string
  cardHandleToken: string
  paymentType: string
}

// TODO missing translations
export const BuyProcess = () => {
  const { authenticated } = useOnlyAuthenticated()
  const { buyerProfile } = useContext(authContext)
  const [step, setStep] = useState<number>(stepperProcess.selectAddress)
  const [selectedBuyerAddresses, setSelectedBuyerAddresses] =
    useState<BuyerShippingAddress>()
  const { listingId } = useParams()
  const [selectedCarrier, setSelectedCarrier] =
    useState<ShippingCostResponseInterface>()
  const [selectedCard, setSelectedCard] = useState<PaymentCard>()
  const [paymentHandleToken, setPaymentHandleToken] =
    useState<PaymentHandleToken>()
  const [costs, setCosts] = useState<PaymentDetailsResponse>()
  const {
    purchaseAllowed,
    loading: loadingLevelCheck,
    requiredLevel,
    currentLevel: beingId,
  } = useLevelCheck(listingId)

  const { error, fromError } = useNotify()
  const navigate = useNavigate()

  const onSelectShippingAddress = async (
    selectedAddress: BuyerShippingAddress,
  ) => {
    try {
      const userService = new UserService()
      const addressValidation =
        await userService.validateAddress(selectedAddress)

      if (!addressValidation.deliveryService.isValid) {
        error(addressValidation.deliveryService.message)
        return
      }

      if (!addressValidation.taxService.isValid) {
        error(addressValidation.taxService.message)
        return
      }

      setSelectedBuyerAddresses(selectedAddress)
    } catch (err) {
      // TODO: add translate
      fromError({
        error: err as Error,
        defaultMessage: 'Error while validating the address',
      })
    }
  }

  const handleConfirmPurchase = async (result: TokenizationResponse) => {
    try {
      if (
        !result ||
        !selectedBuyerAddresses ||
        !selectedCarrier ||
        !listingId
      ) {
        return
      }
      const listing = await ListingService.getListingById(listingId!)

      const response = await ListingService.validateOrderPayment({
        currencyCode: result.currencyCode,
        merchantRefNum: result.merchantRefNum,
        paymentHandleToken: result.paymentHandleToken,
        listingId: listingId,
        shippingAddress:
          selectedBuyerAddresses as unknown as PaymentOrderShippingAddress, // TODO these addresses may be different
        billingAddress:
          selectedBuyerAddresses as unknown as PaymentOrderBillingAddress,
        concurrencyStamp: listing.concurrencyStamp,
        paymentType: paymentHandleToken?.paymentType || result.paymentType,
        serviceType: selectedCarrier?.serviceType!,
        slug: selectedCarrier?.slug!,
      } as PaymentOrder)

      navigate(`/orderConfirmation/${response.id}`)
    } catch (e) {
      Logger.error('Error while validating the payment', undefined, e as Error)
      fromError({
        error: e as Error,
        defaultMessage: 'Error while validating the payment',
      })
    }
  }

  const handlePaymentFailed = (errorMessage?: string) => {
    error(errorMessage ?? 'Error while validating the payment')
  }

  const handleFetchSingleUseToken = async () => {
    try {
      if (!selectedCard) {
        return
      }

      const userService = new UserService()
      const singleUseToken = await userService.getSingleUseToken(
        buyerProfile?.id!,
      )

      // TODO: the backend returns all payment handles without cardId, we need to filter the selected one
      const found = singleUseToken.paymentHandles.find(
        (x) =>
          x.card.lastDigits === selectedCard.lastDigits &&
          x.card.holderName === selectedCard.holderName &&
          x.card.type === selectedCard.type &&
          +x.card.cardExpiry.month === selectedCard.cardExpiry.month &&
          +x.card.cardExpiry.year === selectedCard.cardExpiry.year,
      )

      if (!found) {
        throw new Error('Card not found')
      }

      setPaymentHandleToken({
        profileHandleToken: singleUseToken.singleUseCustomerToken,
        cardHandleToken: found.paymentHandleToken,
        paymentType: found.paymentType ?? 'CARD',
      })
    } catch (e) {
      Logger.error('Error while loading payment details', undefined, e as Error)
      fromError({
        error: e as Error,
        defaultMessage: 'Error while loading payment details',
      })
    }
  }

  const handleCalculateTotalCost = async () => {
    try {
      const paymentDetails = await ListingService.getPaymentDetails({
        listingId: listingId!,
        slug: selectedCarrier?.slug!,
        serviceType: selectedCarrier?.serviceType!,
        country: selectedBuyerAddresses?.country!,
        city: selectedBuyerAddresses?.city!,
        state: selectedBuyerAddresses?.state!,
        zip: selectedBuyerAddresses?.zip!,
      })

      setCosts(paymentDetails)
    } catch (e) {
      Logger.error('Error while calculating total cost', undefined, e as Error)
      fromError({
        error: e as Error,
        defaultMessage: 'Error while calculating total cost',
      })
    }
  }

  useEffect(() => {
    if (!selectedBuyerAddresses || !selectedCarrier) {
      return
    }

    handleCalculateTotalCost()
  }, [selectedBuyerAddresses, selectedCarrier])

  useEffect(() => {
    if (!selectedCard) return

    handleFetchSingleUseToken()
  }, [selectedCard])

  const bodyContent = useMemo(() => {
    switch (step) {
      case stepperProcess.selectAddress:
        return (
          <BuyerAddresses
            setStep={setStep}
            setSelectedBuyerAddresses={onSelectShippingAddress}
            selectedBuyerAddresses={selectedBuyerAddresses}
          />
        )
      case stepperProcess.selectCarrier:
        return (
          <CarrierSelection
            shippingAddress={selectedBuyerAddresses}
            setStep={setStep}
            setSelectedCarrier={setSelectedCarrier}
            selectedCarrier={selectedCarrier}
            listingId={listingId!}
          />
        )

      case stepperProcess.selectPayment:
        return (
          <PaymentMethods
            shippingAddress={selectedBuyerAddresses}
            selectedCard={selectedCard}
            onSelectCard={setSelectedCard}
            totalPrice={costs?.amountPaysafe ? Number(costs.amountPaysafe) : 0}
            onPaymentComplete={handleConfirmPurchase}
            onPaymentFailed={handlePaymentFailed}
            handleToken={paymentHandleToken}
            setStep={setStep}
          />
        )

      case stepperProcess.summary:
        return (
          <Summary
            shippingAddress={selectedBuyerAddresses!}
            selectedCard={selectedCard!}
            totalPrice={costs?.amountPaysafe ? Number(costs?.amountPaysafe) : 0}
            displayPrice={costs?.amount ? `$${costs?.amount}` : '-'}
            costs={costs!}
            onPaymentComplete={handleConfirmPurchase}
            onPaymentFailed={handlePaymentFailed}
            handleToken={paymentHandleToken!}
            listingId={listingId!}
          />
        )

      default:
        return null
    }
  }, [
    step,
    selectedBuyerAddresses,
    selectedCarrier,
    listingId,
    selectedCard,
    setSelectedBuyerAddresses,
    setSelectedCarrier,
    handleConfirmPurchase,
    setStep,
    setSelectedCard,
  ])

  if (!purchaseAllowed || !authenticated) {
    return (
      <div className="min-h-[80vh]">
        <section
          className={classNames(
            'mx-auto max-w-7xl sm:px-2 lg:px-8 bg-white transition-all duration-300 ease-in-out',
            loadingLevelCheck || !authenticated
              ? 'flex items-center justify-center py-12 sm:py-48 mb-12'
              : 'py-6 sm:py-8',
          )}
        >
          <div className="mx-auto max-w-2xl px-4 lg:max-w-4xl lg:px-0 flex flex-col">
            {loadingLevelCheck || !authenticated ? (
              <Spinner />
            ) : (
              <>
                <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                  Insufficient Being ID level
                </h1>
                <p className="mt-2 text-sm text-gray-500">
                  This product requires a better Being ID level to purchase.
                  Please upgrade your being ID level to purchase this product.
                </p>

                <div className="flex items-center mt-12 gap-10">
                  <BeingIdLevelCard
                    label="Required"
                    level={requiredLevel!}
                    href="/profile/digitalIdentity#being-id"
                  />
                  <BeingIdLevelCard
                    label="Current"
                    level={Number(beingId)!}
                    href="/profile/digitalIdentity#being-id"
                    mode="warn"
                  />
                </div>
                <Link
                  to="/profile/digitalIdentity#being-id"
                  className="font-semibold flex items-center gap-3 text-primary-600 mt-8 "
                >
                  <span>Improve your Being ID level</span>
                  <Icon icon="arrowRight" className="fill-primary-600 size-3" />
                </Link>
              </>
            )}
          </div>
        </section>
      </div>
    )
  }

  return (
    <div className="w-full flex flex-col sm:grid sm:grid-cols-5 gap-2">
      <div className="sm:py-3 sm:px-6 sm:col-span-1">
        <Stepper step={step} />
      </div>
      <div className="py-3 sm:px-6 sm:col-span-3">{bodyContent}</div>
      <div className="py-3 sm:px-6 sm:col-span-1">
        <TotalCard costs={costs} />
      </div>
    </div>
  )
}
