import i18nKeys from '@/common/i18nKeys'
import type { DisplayItemWithDescriptionAndAction } from '@/common/types'

import type { RadioOptionItem } from '..'
import { PayoutCurrencyType, ServicePaymentTerms } from '../types'

export const PAYMENT_METHODS: DisplayItemWithDescriptionAndAction[] = [
  {
    id: PayoutCurrencyType.USDollars,
    name: i18nKeys.sell.nav.payment.usd.name,
    description: i18nKeys.sell.nav.payment.usd.description,
    action: i18nKeys.sell.nav.payment.usd.action,
  },
  {
    id: PayoutCurrencyType.Cryptocurrency,
    name: i18nKeys.sell.nav.payment.crypto.name,
    description: i18nKeys.sell.nav.payment.crypto.description,
    action: i18nKeys.common.soon,
  },
]

export const servicePaymentTerms: RadioOptionItem[] = [
  {
    id: ServicePaymentTerms.TimeOfSale,
    name: 'Payment due at the time of sale.',
    description: 'You will receive payment when the service is sold.',
  },
  {
    id: ServicePaymentTerms.FiftyFifty,
    name: 'Payment due 50% at the time of sale and 50% at the time of service.',
    description:
      'You will receive 50% of the payment when the service is sold and the remaining 50% when the service is provided.',
  },
  {
    id: ServicePaymentTerms.TimeOfService,
    name: 'Payment due at the time of service.',
    description: 'You will receive payment when the service is provided.',
  },
  {
    id: ServicePaymentTerms.BuyerInspection,
    name: 'Payment due at the time of service and buyer inspection.',
    description:
      'You will receive payment when the service is provided and the buyer has inspected it.',
  },
]
