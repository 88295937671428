import React from 'react'

import { Input } from '@/common/components'
import { CheckboxWithDescription } from '@/common/components/form/CheckboxWithDescription'

import { SellPage, PageActionButtons, OptionCards } from '..'
import { servicePricing } from '../constants/service-pricing'
import { useServiceListing } from '../context/service-listing-context'

export default function ServicePricingPage() {
  const pricingType = useServiceListing((state) => state.pricingType)
  const isNegotiable = useServiceListing((state) => state.isNegotiable)
  const areMaterialsIncluded = useServiceListing(
    (state) => state.areMaterialsIncluded,
  )
  const price = useServiceListing((state) => state.price)
  const update = useServiceListing((state) => state.update)

  return (
    <SellPage title="Indicate the price of the service or specify if it's negotiable.">
      <OptionCards
        className="flex flex-col sm:flex-row gap-2 sm:gap-8 w-full mt-8"
        options={servicePricing}
        selected={pricingType}
        onChange={(v) => update('pricingType', v)}
      />

      <div className="flex flex-col w-full mt-12 gap-4">
        <Input
          id="price"
          name="price"
          label={pricingType.title}
          placeholder={pricingType.description}
          onChange={(e) => update('price', e.target.value)}
          value={price}
        />

        <div className="space-y-5">
          <CheckboxWithDescription
            label="Is negotiable"
            description="You can negotiate the price with the client."
            checked={isNegotiable}
            onChange={(v) => update('isNegotiable', v)}
          />
          <CheckboxWithDescription
            label="Materials included"
            description="The price includes the cost of materials."
            checked={areMaterialsIncluded}
            onChange={(v) => update('areMaterialsIncluded', v)}
          />
        </div>
      </div>
      <PageActionButtons />
    </SellPage>
  )
}
