import React, { useMemo, useState } from 'react'

import { Button, PaymentCardInfo } from '@/common/components'
import type { TokenizationResponse } from '@/common/components/Paysafe/PaysafeClient'
import { useNotify, useProfile } from '@/common/hooks'
import type { BuyerShippingAddress } from '@/common/types'
import type { PaymentCard } from '@/core'
import { BuyPage } from '@/features/buyProcess/BuyPage'
import { CvvModal } from '@/features/buyProcess/CvvModal'
import { AddressCard } from '@/features/listing'

import type { PaymentHandleToken } from './PurchaseSubscriptionPage'

export type SubscriptionConfirmationProps = {
  selectedCard: PaymentCard
  billingAddress: BuyerShippingAddress
  price: number
  priceDisplay: string
  paymentHandleToken: PaymentHandleToken
  onBack: () => void
  onConfirm: (result: TokenizationResponse) => void | Promise<void>
}

export const SubscriptionConfirmation: React.FC<
  SubscriptionConfirmationProps
> = (props) => {
  const { error } = useNotify()

  const { profile } = useProfile()

  const [cvvModalOpen, setCvvModalOpen] = useState(false)

  const cardHolderName = useMemo(() => {
    if (!profile) return undefined

    const segments = [profile.firstName, profile.middleName, profile.lastName]

    return segments.filter(Boolean).join(' ')
  }, [profile?.firstName, profile?.middleName, profile?.lastName])

  return (
    <>
      <BuyPage
        heading="Confirm subscription"
        description="Make sure everything is correct."
      >
        <h3 className="font-medium text-base mt-4">Payment method</h3>
        <PaymentCardInfo
          card={props.selectedCard}
          className="w-full cursor-default"
        />

        <h3 className="font-medium text-base mt-4">Billing address</h3>
        <AddressCard
          address={props.billingAddress.address}
          name={props.billingAddress.name}
          addressType={props.billingAddress.type}
          className="w-full cursor-default"
          mode="button"
        />

        <div className="flex justify-end mt-16">
          <Button
            onClick={() => setCvvModalOpen(true)}
            disabled={!props.paymentHandleToken}
          >
            Confirm $10
          </Button>
        </div>
      </BuyPage>

      <CvvModal
        totalPrice={props.price}
        billingAddress={props.billingAddress}
        cardHolderName={cardHolderName!}
        handleToken={props.paymentHandleToken}
        onPaymentComplete={props.onConfirm}
        onPaymentFailed={() => error('Failed to confirm payment.')}
        open={cvvModalOpen}
        onClose={() => setCvvModalOpen(false)}
      />
    </>
  )
}
