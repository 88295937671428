import { t } from 'i18next'
import React from 'react'
import { Link } from 'react-router-dom'

import { Icon } from '../../../common/components'
import { colors } from '../../../theme'
import type { RouteWithMetadata } from '../../../types'

export interface StepsNavigationBarProps {
  /**
   * The list of routes to show on the nav bar
   */
  routes: RouteWithMetadata[]

  /**
   * Styles classes
   */
  className?: string

  /**
   * Index of the current step, based on the routes prop
   */
  currentStep: number
}

/**
 * A Navigation bar to display steps
 */
export function StepsNavigationBar(props: StepsNavigationBarProps) {
  return (
    <nav aria-label="Progress" className={props.className}>
      {/* TODO: mobile navbar */}
      <ol className="overflow-hidden min-w-40">
        {props.routes.map((step, stepIdx) => {
          let status = 'upcoming'
          if (props.currentStep === stepIdx) {
            status = 'current'
          } else if (stepIdx < props.currentStep) {
            status = 'complete'
          }

          return (
            <li
              key={step.route.path}
              className={`relative ${stepIdx !== props.routes.length - 1 ? 'pb-10' : ''}`}
            >
              {status === 'complete' ? (
                <>
                  {stepIdx !== props.routes.length - 1 ? (
                    <div
                      className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-primary"
                      aria-hidden="true"
                    />
                  ) : null}
                  <Link
                    to={step.route.path!}
                    className="group relative flex items-start"
                  >
                    <span className="flex h-9 items-center">
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-primary group-hover:bg-primary-800">
                        <Icon icon="check" size={20} color={colors.surface} />
                      </span>
                    </span>
                    <span className="ml-4 flex min-w-0 flex-col">
                      <span className="text-sm font-medium">
                        {t(step.label!)}
                      </span>
                      <span className="text-sm text-gray-500">
                        {t(step.description!)}
                      </span>
                    </span>
                  </Link>
                </>
              ) : status === 'current' ? (
                <>
                  {stepIdx !== props.routes.length - 1 ? (
                    <div
                      className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                      aria-hidden="true"
                    />
                  ) : null}
                  <Link
                    to={step.route.path!}
                    className="group relative flex items-start"
                    aria-current="step"
                  >
                    <span className="flex h-9 items-center" aria-hidden="true">
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary bg-white">
                        <span className="h-2.5 w-2.5 rounded-full bg-primary" />
                      </span>
                    </span>
                    <span className="ml-4 flex min-w-0 flex-col">
                      <span className="text-sm font-medium text-primary">
                        {t(step.label!)}
                      </span>
                      <span className="text-sm text-gray-500">
                        {t(step.description!)}
                      </span>
                    </span>
                  </Link>
                </>
              ) : (
                <>
                  {stepIdx !== props.routes.length - 1 ? (
                    <div
                      className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="group relative flex items-start">
                    <span className="flex h-9 items-center" aria-hidden="true">
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                        <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                      </span>
                    </span>
                    <span className="ml-4 flex min-w-0 flex-col">
                      <span className="text-sm font-medium text-gray-500">
                        {t(step.label!)}
                      </span>
                      <span className="text-sm text-gray-500">
                        {t(step.description!)}
                      </span>
                    </span>
                  </div>
                </>
              )}
            </li>
          )
        })}
      </ol>
    </nav>
  )
}
