import React from 'react'

import i18nKeys from '@/common/i18nKeys'
import { featureFlags } from '@/config'
import { AddPaymentCardPage } from '@/features/profile/chainItPay/pages/AddPaymentCardPage'
import { AddPaymentDetailsPage } from '@/features/profile/chainItPay/pages/AddPaymentDetailsPage'
import { ChainItPay } from '@/features/profile/chainItPay/pages/ChainItPay'
import { OrderHistoryPage } from '@/features/profile/tracking'
import { OrderDetailPage } from '@/features/profile/tracking/pages/OrderDetailPage'

import { BeingId } from '../features/profile/beingId/BeingId'
import { DigitalIdentity } from '../features/profile/digitalIdentity/DigitalIdentity'
import { MyLibrary } from '../features/profile/myLibrary/MyLibrary'
import { Organization } from '../features/profile/organization/Organization'
import { PersonalInformation } from '../features/profile/personalInformation/PersonalInformation'
import { Sell } from '../features/profile/sell/Sell'
import type { RouteWithMetadata } from '../types'

export const profileRoutes: RouteWithMetadata[] = [
  {
    label: i18nKeys.profile.digitalIdentity.title,
    route: {
      path: 'digitalIdentity',
      element: <DigitalIdentity />,
    },
    icon: 'userCircle',
  },
  {
    label: i18nKeys.profile.personalInformation.title,
    route: {
      path: 'personalInformation',
      element: <PersonalInformation />,
    },
    icon: 'idCard',
  },
  {
    label: i18nKeys.profile.beingId.title,
    route: {
      path: 'beingId',
      element: <BeingId />,
    },
    icon: 'document',
    hideOnMenu: !featureFlags.beingIdProfile,
  },
  {
    label: i18nKeys.profile.chainItPay,
    route: {
      path: 'chainItPay',
      element: <ChainItPay />,
    },
    icon: 'wallet',
  },
  {
    label: i18nKeys.profile.organization,
    route: {
      path: 'organization',
      element: <Organization />,
    },
    icon: 'city',
    hideOnMenu: !featureFlags.organizationNavigation,
  },
  {
    label: i18nKeys.profile.seller,
    route: {
      path: 'seller',
      element: <Sell />,
    },
    icon: 'idBadge',
    showSeparator: true,
  },
  {
    label: i18nKeys.profile.sell,
    route: {
      path: 'sell',
      element: <></>,
    },
    externalPath: '/sell',
    icon: 'tag',
  },
  {
    label: i18nKeys.profile.myLibrary.title,
    route: {
      path: 'myLibrary',
      element: <MyLibrary />,
    },
    icon: 'shop',
  },
  {
    label: i18nKeys.profile.order.title,
    route: {
      path: 'orderTracking',
      element: <OrderHistoryPage />,
    },
    icon: 'list',
  },
  {
    route: {
      path: 'card',
      element: <AddPaymentCardPage />,
    },
    hideOnMenu: true,
  },
  {
    route: {
      path: 'addPayment',
      element: <AddPaymentDetailsPage />,
    },
    hideOnMenu: true,
  },
  {
    route: {
      path: 'order/:id',
      element: <OrderDetailPage />,
    },
    hideOnMenu: true,
  },
]
