import type { CardOption } from '..'
import { ServiceSellMethod } from '../types'

export const servicePricing: CardOption[] = [
  {
    id: ServiceSellMethod.FixedPrice,
    title: 'Fixed Price',
    description: 'Get paid exactly this amount for offering this service.',
  },
  {
    id: ServiceSellMethod.HourlyRate,
    title: 'Hourly rate',
    description: 'Get paid for the amount of hours you work.',
  },
]
