import { AssetService } from '@bit-ui-libs/common'
import type {
  AddressTypeEnum,
  ChainBaseEvent,
  CreateListingRequest,
  CustomerAsset,
  EBeingLevel,
  GetAssetsRequest,
  IpfsMetadata,
  PagedResponse,
  SellingMethodEnum,
  StepEnum,
} from '@bit-ui-libs/common'

import envVariables from '@/common/envVariables'
import type { FeaturedCategory, MarketplaceMainCategory } from '@/common/types'
import type { TaxCategory } from '@/common/types/taxes'
import { mockStepTemplate, mockSteps } from '@/mock'
import { mockedFeaturedCategories } from '@/mock/category'

import { mapQueryParams } from '..'
import { applicationAxiosClient } from '../axios'
import { doGet } from '../fetch'
import type { CustomerAssetWithPrice } from '../marketplace'

const assetService = new AssetService({
  route: 'asset',
  apiUrl: envVariables.API_BASE_URL,
  axiosInstance: applicationAxiosClient,
})

// todo: move interfaces to separate files
export interface GetCategoriesParams {
  parentId?: string
  page?: number
  search?: string
}

export interface GetAssetTypesParams {
  categoryId?: string
  page?: number
  search?: string
  pageSize?: number
}

export interface GetTaxCategoryParams {
  search?: string
  page?: number
  pageSize?: number
}

export interface GetAssetTemplateParams {
  typeId: string
  step: StepEnum
}

//Added assetVdt because it doesnt exist on common library
export interface AssetVdt {
  platform: string
  immutableTime: string
  nftTokenId: string
  nftMetadataUrl: string
  nftSmartContractAddress: string
  immutableTransactionHash: string
  walletAddress: string
  qrId: string
  assetId: string
  ipfsMetadata: IpfsMetadata
}

export enum ETransactionCompleteness {
  OnTimeOfSale = 'OnTimeOfSale',
  OnItemDelivered = 'OnItemDelivered',
  OnDeliveredAndInspected = 'OnDeliveredAndInspected',
}

export interface CreateListingParams {
  id: string
  sellingMethod: SellingMethodEnum
  price: number
  startAt: string
  endAt?: string
  description: string
  isFreeShipping: boolean
  shippingDetail?: {
    shipFrom: {
      name: string
      type: AddressTypeEnum
      address: string
      addressLine1: string
      addressLine2?: string
      city: string
      state: string
      zip: string
      country: string
      phone?: string
    }
    weight: {
      value: number
      unit: string
    }
    dimension: {
      depth: number
      width: number
      height: number
      unit: string
    }
    handlingFee: number
  }
  revenue: number
  buyerBeingId: EBeingLevel
  payoutAccountId: string
  completeness: ETransactionCompleteness
  taxCode?: string
}

export type FeaturedCategoryResponse = Record<
  MarketplaceMainCategory,
  FeaturedCategory[]
>

export class AssetsService {
  async getAssets(ownerId: string, isListed: boolean) {
    return assetService.list({ ownerId, isListed })
  }

  async getCategories(params?: GetCategoriesParams) {
    const name = !params?.search?.trim()?.length ? undefined : params.search
    return assetService.searchCategories({
      parentId: params?.parentId,
      $page: params?.page ?? 1,
      name,
    })
  }

  async getCategoryById(id: string) {
    return assetService.getCategoryById(id)
  }

  async getAssetTypes(params?: GetAssetTypesParams) {
    const name = !params?.search?.trim()?.length ? undefined : params.search
    return assetService.searchAssetTypes({
      categoryId: params?.categoryId,
      name,
      $page: params?.page ?? 1,
      $perPage: params?.pageSize,
    })
  }

  async getTaxCategories(params?: GetTaxCategoryParams) {
    const url = mapQueryParams('/assets/v1/tax-categories', {
      name: params?.search,
      $page: params?.page ?? 1,
      $perPage: params?.pageSize,
    })

    return doGet(url) as Promise<PagedResponse<TaxCategory>>
  }

  async getTemplate(params: GetAssetTemplateParams) {
    return mockStepTemplate[params.step]
    // return assetService.getActiveAssetTemplate({
    //   typeId: params.typeId,
    //   step: params.step,
    // })
  }

  // todo: remove mocked steps
  async getAssetTypeById(assetTypeId: string) {
    const res = await assetService.getAssetType(assetTypeId)
    res.steps = mockSteps
    return res
  }

  static async getUserAssets(
    options: GetAssetsRequest,
  ): Promise<PagedResponse<CustomerAsset>> {
    return assetService.list(options)
  }

  static async getUserAssetById(
    assetId: string,
  ): Promise<CustomerAssetWithPrice> {
    const res = await doGet(`/assets/v1/asset/${assetId}`)

    return {
      ...res,
      tags: res.tags ?? [],
    } as CustomerAssetWithPrice
  }

  static async getAssetVDT(assetId: string): Promise<AssetVdt> {
    return doGet(`/web3/v1/nft/asset-vdt/${assetId}`)
  }

  static async getAssetWhereInformation(
    eventId: string,
  ): Promise<ChainBaseEvent> {
    return assetService.getAssetChainDetails(eventId)
  }

  async getByEventId(eventId: string) {
    return doGet(`/assets/v1/asset/${eventId}/event`) as Promise<CustomerAsset>
  }

  async createListing(params: CreateListingParams) {
    return assetService.createListing(params as CreateListingRequest) // TODO bit-ui-libs CreateListingRequest is outdated
  }

  async getFeaturedCategories(): Promise<FeaturedCategoryResponse> {
    return mockedFeaturedCategories
  }

  static async getPublicByEventId(eventId: string) {
    return doGet(
      `/assets/v1/asset/tokens-public?eventIdRef=${eventId}`,
    ) as Promise<PagedResponse<CustomerAssetWithPrice>>
  }

  static async getPublicByAssetId(assetIds: string[]) {
    const query = assetIds.join('&$ids=')
    return doGet(`/assets/v1/asset/tokens-public?$ids=${query}`) as Promise<
      PagedResponse<CustomerAssetWithPrice>
    >
  }

  static async getPublicByListingId(listingId: string) {
    return doGet(
      `/marketplace/v1/listings/${listingId}/public`,
    ) as Promise<CustomerAssetWithPrice>
  }
}
