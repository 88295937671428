import axios from 'axios'
import { t } from 'i18next'
import React, { useCallback, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'

import {
  Button,
  PaymentCardInfo,
  SelectPaymentSlideOver,
} from '@/common/components'
import type { TokenizationResponse } from '@/common/components/Paysafe/PaysafeClient'
import { startVenmoTokenize } from '@/common/components/Paysafe/PaysafeClient'
import {
  getStateCode,
  selectCountryFromSplitString,
} from '@/common/constants/countries'
import envVariables from '@/common/envVariables'
import i18nKeys from '@/common/i18nKeys'
import type { Address } from '@/common/types'
import { Environment } from '@/common/types'
import { Logger } from '@/config'
import type { PaymentCard } from '@/core'
import type { GetPaymentCredentialsResponse } from '@/core/marketplace/payments.service'
import { PaymentsService } from '@/core/marketplace/payments.service'

import { EmptyCard } from '../listing'

import styles from './SelectCard.module.css'

import type { PaymentHandleToken } from '.'

export type SelectCardProps = {
  enableGooglePay?: boolean
  selectedCard?: PaymentCard
  cards?: PaymentCard[]
  totalPrice: number
  onSelectCard?: (card: PaymentCard) => void
  onCreateCard?: () => void
  billingAddress: Address
  cardHolderName: string
  handleToken?: PaymentHandleToken
  onPaymentComplete?: (result: TokenizationResponse) => void | Promise<void>
  onPaymentFailed?: (error?: string) => void | Promise<void>
  onContinue: () => void
}

const googlePayConfiguration = {
  apiVersion: 2,
  apiVersionMinor: 0,
  allowedPaymentMethods: [
    {
      type: 'CARD',
      parameters: {
        allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
        allowedCardNetworks: ['AMEX', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
        assuranceDetailsRequired: true,
      },
      tokenizationSpecification: {
        type: 'PAYMENT_GATEWAY',
        parameters: {
          gateway: '',
          gatewayMerchantId: '',
        },
      },
    },
  ],
}

// FIXME is this incomplete? have we removed card payment?
// TODO replace Google Pay hardcoded keys with environment variables or something else
export const SelectCard: React.FC<SelectCardProps> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  let googlePayInstance: any

  useEffect(() => {
    // Add Google Pay script to DOM
    const script = document.createElement('script')
    script.src = 'https://pay.google.com/gp/p/js/pay.js'
    script.async = true
    document.body.appendChild(script)
    script.onload = async () => setupGooglePay()
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const setupGooglePay = async () => {
    try {
      googlePayInstance = new window.google.payments.api.PaymentsClient({
        environment:
          envVariables.ENVIRONMENT === Environment.PRODUCTION
            ? 'PRODUCTION'
            : 'TEST',
      })

      const paymentCredentials = await PaymentsService.getPaymentsCredentials()
      googlePayConfiguration.allowedPaymentMethods[0].tokenizationSpecification.parameters =
        {
          gateway: paymentCredentials?.providerName,
          gatewayMerchantId: paymentCredentials?.publicProviderId,
        }

      const client = await googlePayInstance.isReadyToPay(
        googlePayConfiguration,
      )
      if (client?.result) {
        // Add Google Pay button to container
        const button = googlePayInstance.createButton({
          buttonColor: 'default',
          buttonType: 'buy',
          buttonRadius: 4,
          onClick: async () => onGooglePayButtonClicked(paymentCredentials),
        })
        const googlePayContainer = document.getElementById(
          'google-pay-container',
        )
        if (googlePayContainer) {
          googlePayContainer.appendChild(button)
        }
      }
    } catch (error) {
      Logger.error("Couldn't setup Google Pay", undefined, error as Error)
      // TODO notify the user about the error
    }
  }

  async function onGooglePayButtonClicked(
    credentials: GetPaymentCredentialsResponse,
  ) {
    try {
      const paymentDataRequest = {
        ...googlePayConfiguration,
        merchantInfo: {
          merchantName: credentials.googleMerchantName,
          merchantId: credentials.googleMerchantId,
        },
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPrice: (props.totalPrice / 100).toString(),
          currencyCode: 'USD',
          countryCode: 'US',
        },
      }
      const paymentData =
        await googlePayInstance.loadPaymentData(paymentDataRequest)

      await tokenize(paymentData, credentials.accountId, credentials.publicKey)
    } catch (error) {
      Logger.error(
        'Google Pay - onGooglePayButtonClicked error: ',
        undefined,
        error as Error,
      )
      // TODO notify user
    }
  }

  async function tokenize(
    paymentData: any,
    accountId: string,
    publicKey: string,
  ) {
    Logger.debug('tokenize paymentData: ', paymentData)
    if (paymentData.statusCode === 'CANCELED') {
      return
    }
    const merchantRefNum = uuid()

    const requestBody = {
      merchantRefNum: merchantRefNum,
      accountId: accountId,
      transactionType: 'PAYMENT',
      paymentType: 'CARD',
      amount: props.totalPrice,
      currencyCode: 'USD',
      googlePay: { googlePayPaymentToken: paymentData },
      billingDetails: {
        nickName: 'Home',
        state: getStateCode(props.billingAddress.state),
        city: props.billingAddress.city,
        zip: props.billingAddress.zip,
        street: props.billingAddress.address,
        country: selectCountryFromSplitString(props.billingAddress.country),
      },
    }
    try {
      const paysafeApiUrl = `https://api${envVariables.ENVIRONMENT !== Environment.PRODUCTION ? '.test' : ''}.paysafe.com`
      const response = await axios.post(
        `${paysafeApiUrl}/paymenthub/v1/singleusepaymenthandles`,
        requestBody,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic ' + publicKey,
          },
        },
      )

      Logger.info('tokenize response from Paysafe', response)
      if (props.onPaymentComplete) {
        props.onPaymentComplete({
          paymentHandleToken: response?.data?.paymentHandleToken,
          merchantRefNum: merchantRefNum,
          amount: props.totalPrice,
          currencyCode: 'USD',
          paymentType: 'CARD',
        } as TokenizationResponse)
      }
    } catch (error) {
      Logger.error('Google Pay - tokenize error', undefined, error as Error)
      // TODO notify user
    }
  }

  const handleSelectCard = (card?: PaymentCard) => {
    setIsModalOpen(false)

    if (props.onSelectCard && card) {
      props.onSelectCard(card)
    }
  }

  const handleCreateCard = () => {
    setIsModalOpen(false)
    if (props.onCreateCard) {
      props.onCreateCard()
    }
  }

  const handleFilterCard = useCallback(
    (search: string, item: PaymentCard) =>
      item.holderName.toLowerCase().includes(search) ||
      item.lastDigits.includes(search) ||
      item.type.toLowerCase().includes(search),
    [],
  )

  function startVenmoPayment() {
    startVenmoTokenize(
      props.cardHolderName,
      props.billingAddress,
      props.totalPrice,
    )
      .then((result) => {
        if (result && props.onPaymentComplete) {
          props.onPaymentComplete(result)
        }
      })
      .catch((error) => {
        Logger.error('Venmo payment failed', undefined, error as Error)
        if (props.onPaymentFailed) {
          props.onPaymentFailed(error)
        }
      })
  }

  return (
    <>
      <div
        onClick={startVenmoPayment}
        className={styles.venmo}
        onKeyDown={startVenmoPayment}
        role="presentation"
        style={{ display: 'none' }}
      >
        VENMO
      </div>
      {props.enableGooglePay ? (
        <div id="google-pay-container" className={styles.googlePay}></div>
      ) : null}
      <div className="mx-auto max-w-2xl mt-12 w-full h-full flex flex-col justify-between flex-1">
        {props.selectedCard ? (
          <>
            <PaymentCardInfo
              card={props.selectedCard}
              onClick={() => setIsModalOpen(true)}
              className="w-full"
              rightComponent={
                // TODO add translation
                <span className="text-primary-500 font-medium">Change</span>
              }
            />
          </>
        ) : (
          <EmptyCard
            onClick={() => setIsModalOpen(true)}
            icon="card"
            text="Select a credit/debit card"
            className="w-full"
          />
        )}
      </div>

      <div className="flex justify-end mt-16">
        <Button onClick={props.onContinue} disabled={!props.selectedCard}>
          {t(i18nKeys.ui.continue)}
        </Button>
      </div>

      <SelectPaymentSlideOver
        open={isModalOpen}
        onClose={handleSelectCard}
        // TODO add translations
        emptyMessage="No cards found. Add a new card to continue."
        emptySearch={(search) =>
          `No cards found while looking for \"${search}\".`
        }
        searchPlaceholder="Search for a card"
        RenderItem={({ item, index }) => (
          <PaymentCardInfo
            key={index}
            card={item}
            onClick={() => handleSelectCard(item)}
            rightComponent={
              <span className="text-primary-500 font-medium">Choose</span>
            }
          />
        )}
        items={props.cards ?? []}
        title="Choose a card"
        onSearch={handleFilterCard}
        onCreate={handleCreateCard}
      />
    </>
  )
}
