import envVariables from '../../common/envVariables'
import type { GenerateStateOpts } from '../../core/auth'
import { getAuthUrl } from '../../core/auth'

export const login = async () => {
  const opts: GenerateStateOpts = {
    clientId: envVariables.AUTH0_CLIENT_ID,
    redirectUri: `${window.location.origin}/onboarding/login/verification`,
    authType: 'sign-in',
  }
  const res = await getAuthUrl(opts)
  // localStorage.setItem('code_verifier', codeVerifier);
  window.location.href = res.url
}
