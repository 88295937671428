import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import React from 'react'
import type { IconType } from 'react-icons'
import {
  FaRegGrinAlt,
  FaWallet,
  FaCheckCircle,
  FaInfoCircle,
  FaBitcoin,
  FaSpinner,
  FaRegCompass,
} from 'react-icons/fa'
import {
  FaArrowRight,
  FaBars,
  FaBaseball,
  FaBoxOpen,
  FaCar,
  FaCartShopping,
  FaChampagneGlasses,
  FaCheck,
  FaCircleExclamation,
  FaGavel,
  FaHeart,
  FaHouse,
  FaIcons,
  FaLock,
  FaMagnifyingGlass,
  FaPlus,
  FaPhone,
  FaRegCircleQuestion,
  FaRegUser,
  FaXmark,
  FaCertificate,
  FaLink,
  FaUpload,
  FaRegCircleUser,
  FaRegIdCard,
  FaRegRectangleList,
  FaCity,
  FaTag,
  FaShop,
  FaCopy,
  FaCircleQuestion,
  FaDollarSign,
  FaEnvelope,
  FaPenToSquare,
  FaTrash,
  FaGoogle,
  FaFacebook,
  FaBuildingColumns,
  FaRegStar,
  FaStar,
  FaShareNodes,
  FaRegHeart,
  FaMinus,
  FaUserClock,
  FaMobileScreenButton,
  FaCamera,
  FaCalendarDays,
  FaPaperclip,
  FaRotateRight,
  FaCreditCard,
  FaTableList,
  FaIdBadge,
  FaStore,
  FaTriangleExclamation,
} from 'react-icons/fa6'

import type { IconSource } from '.'

export const IconMapping: Record<IconSource, IconType> = {
  search: FaMagnifyingGlass,
  bars: FaBars,
  cart: FaCartShopping,
  gavel: FaGavel,
  heart: FaHeart,
  baseball: FaBaseball,
  car: FaCar,
  champagneGlasses: FaChampagneGlasses,
  close: FaXmark,
  help: FaRegCircleQuestion,
  category: FaIcons,
  check: FaCheck,
  add: FaPlus,
  error: FaCircleExclamation,
  userCircle: FaRegCircleUser,
  idCard: FaRegIdCard,
  document: FaRegRectangleList,
  wallet: FaWallet,
  city: FaCity,
  tag: FaTag,
  shop: FaShop,
  copy: FaCopy,
  question: FaCircleQuestion,
  dollar: FaDollarSign,
  email: FaEnvelope,
  boxOpen: FaBoxOpen,
  infoCircle: FaInfoCircle,
  checkCircle: FaCheckCircle,
  arrowRight: FaArrowRight,
  lock: FaLock,
  edit: FaPenToSquare,
  trash: FaTrash,
  google: FaGoogle,
  facebook: FaFacebook,
  user: FaRegUser,
  faceScan: FaRegGrinAlt,
  phone: FaPhone,
  home: FaHouse,
  certificate: FaCertificate,
  link: FaLink,
  upDown: (props) => (
    <ChevronUpDownIcon {...props} width={props.size} height={props.height} />
  ),
  upload: FaUpload,
  delete: FaTrash,
  bank: FaBuildingColumns,
  bitcoin: FaBitcoin,
  star: FaRegStar,
  starFilled: FaStar,
  share: FaShareNodes,
  spinner: FaSpinner,
  heartOutline: FaRegHeart,
  minus: FaMinus,
  compass: FaRegCompass,
  mobile: FaMobileScreenButton,
  userClock: FaUserClock,
  camera: FaCamera,
  calendar: FaCalendarDays,
  paperClip: FaPaperclip,
  retry: FaRotateRight,
  card: FaCreditCard,
  list: FaTableList,
  idBadge: FaIdBadge,
  storeIcon: FaStore,
  warn: FaTriangleExclamation,
}
