import type { CardOption } from '..'
import { ListingType } from '../types/listing-type'

export const listingTypeOptions = [
  {
    id: ListingType.Asset,
    title: 'Product',
    description:
      'Represents a physical product that you want to sell on the marketplace.',
  },
  {
    id: ListingType.Service,
    title: 'Service',
    description:
      'Represents a service that you want to offer on the marketplace.',
  },
] as CardOption[]
