import i18nKeys from '@/common/i18nKeys'
import type { DisplayItemWithDescription } from '@/common/types'

import type { CardOption } from '..'
import { AuctionType, SellingMethodType, ServiceListingTerms } from '../types'

export const AUCTION_TYPES: DisplayItemWithDescription[] = [
  {
    id: AuctionType.Timed,
    name: i18nKeys.sell.nav.method.auction.timed,
    description: i18nKeys.sell.nav.method.auction.timedDescription,
  },
  {
    id: AuctionType.Dutch,
    name: i18nKeys.sell.nav.method.auction.dutch,
    description: i18nKeys.sell.nav.method.auction.dutchDescription,
  },
  {
    id: AuctionType.Inverse,
    name: i18nKeys.sell.nav.method.auction.inverse,
    description: i18nKeys.sell.nav.method.auction.inverseDescription,
    disabled: true,
  },
]

export const SELL_METHODS: DisplayItemWithDescription[] = [
  {
    id: SellingMethodType.FixedPrice,
    name: i18nKeys.sell.nav.method.methods.fixed,
    description: i18nKeys.sell.nav.method.methods.fixedDescription,
  },
  {
    id: SellingMethodType.Auction,
    name: i18nKeys.sell.nav.method.methods.auction,
    description: i18nKeys.sell.nav.method.methods.auctionDescription,
  },
]

export const serviceListingTerms: CardOption[] = [
  {
    id: ServiceListingTerms.OneTime,
    title: 'One time',
    description:
      'This listing is for a one time service (e.g. it is available to the first customer that buys).',
  },
  {
    id: ServiceListingTerms.Recurring,
    title: 'Recurring',
    description:
      'This listing is a recurring service (e.g. it is available to be purchased by multiple customers).',
  },
]
