import { t } from 'i18next'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import { Button, PaymentCardIcon } from '@/common/components'
import type { TokenizationResponse } from '@/common/components/Paysafe/PaysafeClient'
import { authContext } from '@/common/context/auth_context'
import i18nKeys from '@/common/i18nKeys'
import type { BuyerShippingAddress } from '@/common/types'
import { ConditionalRendering } from '@/components'
import type {
  CustomerAssetWithPrice,
  PaymentCard,
  PaymentDetailsResponse,
} from '@/core'
import { AssetsService, formatExpirationDate } from '@/core'
import { EventService } from '@/core/events'

import type { PaymentHandleToken } from './BuyProcess'
import { CvvModal } from './CvvModal'

//TODO Improve de component to allow multiple Products
// TODO add translations
// TODO replace icons with custom component

export interface ConfirmationInterface {
  listingId: string
  shippingAddress: BuyerShippingAddress
  selectedCard: PaymentCard
  totalPrice: number
  displayPrice: string
  handleToken: PaymentHandleToken
  onPaymentComplete: (result: TokenizationResponse) => void | Promise<void>
  onPaymentFailed: (error?: string) => void | Promise<void>
  costs: PaymentDetailsResponse
}

export const Summary: React.FC<ConfirmationInterface> = ({
  listingId,
  shippingAddress,
  selectedCard,
  totalPrice,
  displayPrice,
  handleToken,
  onPaymentComplete,
  onPaymentFailed,
  costs,
}) => {
  const [product, setProduct] = useState<CustomerAssetWithPrice>()
  const [proceedPayment, setProceedPayment] = useState<boolean>(false)
  const { profile } = useContext(authContext)

  useEffect(() => {
    getProductInfo()
  }, [listingId])

  const getProductInfo = async () => {
    if (!listingId) {
      return
    }

    const res = await AssetsService.getPublicByListingId(listingId)
    const product = await AssetsService.getUserAssetById(res.assetId)

    const data = {
      ...res,
      ...product,
    } as CustomerAssetWithPrice

    setProduct(data)
  }

  const cardHolderName = useMemo(() => {
    if (!profile) return undefined

    const segments = [profile.firstName, profile.middleName, profile.lastName]

    return segments.filter(Boolean).join(' ')
  }, [profile?.firstName, profile?.middleName, profile?.lastName])

  const cardEnding = useMemo(
    () =>
      t(i18nKeys.profile.order.detail.cardEnding).replace(
        '{LAST_DIGITS}',
        selectedCard.lastDigits,
      ),
    [t, selectedCard.lastDigits],
  )

  const cardExpiration = useMemo(
    () =>
      t(i18nKeys.profile.order.detail.cardExpires).replace(
        '{EXPIRATION}',
        formatExpirationDate(
          selectedCard.cardExpiry.month,
          selectedCard.cardExpiry.year,
        ),
      ),
    [t, selectedCard.cardExpiry],
  )

  let bodyContent = null

  if (product && selectedCard) {
    bodyContent = (
      <div className="min-h-[80vh]">
        <div className="bg-white p-8 max-w-4xl mx-auto sm:min-h-[512px] rounded-md flex flex-col">
          <ul className="mt-6 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-500">
            <li key={product.id} className="flex space-x-6 py-6">
              <img
                src={EventService.buildDocumentImageUrlById(
                  product.serializedImages?.find((x) => x.isMain)?.docId,
                )}
                alt="Not found" //TODO: improve broken image msg
                className="h-32 w-32 flex-none rounded-md bg-gray-100 object-cover object-center"
              />
              <div className="flex-auto space-y-1">
                <h3 className="text-gray-900 text-xl text-bold">
                  <a
                    href={`/search/listing/${listingId}/event/${product.eventIdRef}/detail`}
                  >
                    {product.name}
                  </a>
                </h3>
                <p key={'-prop'}>{product.description}</p>
              </div>
            </li>
          </ul>

          <dl className="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
            <div className="flex justify-between">
              <dt>Subtotal</dt>
              <dd className="text-gray-900">${costs?.sellingPrice}</dd>
            </div>

            <div className="flex justify-between">
              <dt>Shipping</dt>
              <dd className="text-gray-900">${costs?.shippingCost}</dd>
            </div>

            <div className="flex justify-between">
              <dt>Taxes</dt>
              <dd className="text-gray-900">${costs?.costTax}</dd>
            </div>

            <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
              <dt className="text-base">Total</dt>
              <dd className="text-base">{displayPrice}</dd>
            </div>
          </dl>

          <dl className="mt-16 grid grid-cols-2 gap-x-4 text-sm text-gray-600">
            <div>
              <dt className="font-medium text-gray-900">Shipping Address</dt>
              <dd className="mt-2">
                <address className="not-italic">
                  <span className="block">{shippingAddress?.name}</span>
                  <span className="block">{shippingAddress?.address}</span>
                  <span className="block">{shippingAddress?.city}</span>
                </address>
              </dd>
            </div>
            <div>
              <dt className="font-medium text-gray-900">Payment Information</dt>
              <dd className="mt-2 space-y-2 sm:flex sm:space-x-4 sm:space-y-0">
                <div className="flex-none">
                  <PaymentCardIcon type={selectedCard.type} className="w-10" />
                </div>
                <div className="flex-auto">
                  <p className="text-gray-900">{cardEnding}</p>
                  <p>{cardExpiration}</p>
                </div>
              </dd>
            </div>
          </dl>

          <div className="mt-16 border-t border-gray-200 py-6 text-right">
            <div className="flex justify-center">
              <Button onClick={() => setProceedPayment(true)} disabled={!costs}>
                {t(i18nKeys.ui.confirmPurchase)}
              </Button>
            </div>
          </div>
        </div>

        <ConditionalRendering renderIf={proceedPayment}>
          <CvvModal
            totalPrice={totalPrice}
            billingAddress={shippingAddress}
            cardHolderName={cardHolderName!}
            handleToken={handleToken}
            onPaymentComplete={onPaymentComplete}
            onPaymentFailed={onPaymentFailed}
            open={proceedPayment}
            onClose={() => setProceedPayment(false)}
          />
        </ConditionalRendering>
      </div>
    )
  }

  return bodyContent
}
