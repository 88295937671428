import { doGet } from '..'

export type GetPaymentCredentialsResponse = {
  publicKey: string
  accountId: string
  paypalAccountId: string
  venmoAccountId: string
  googleMerchantId: string
  googleMerchantName: string
  providerName: string
  publicProviderId: string
}

export class PaymentsService {
  static async getPaymentsCredentials(): Promise<GetPaymentCredentialsResponse> {
    return doGet(
      '/marketplace/v1/payments/credentials',
    ) as Promise<GetPaymentCredentialsResponse>
  }
}
