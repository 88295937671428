import type { BuyerProfile } from '@bit-ui-libs/common'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Logger } from '@/config'
import type { UserAddressForList } from '@/core'
import { UserService } from '@/core'

import type { BuyerShippingAddress } from '../types'

import { useNotify, useProfile } from '.'

const userService = new UserService()

export type UseBuyerProfileHookParams = {
  navigateOnError?: boolean
  showToastOnError?: boolean
}

export type Validation<T> = {
  data?: T
  isValid: boolean
  isLoading: boolean
}

export const useBuyerProfile = (params: UseBuyerProfileHookParams = {}) => {
  const navigate = useNavigate()
  const { info } = useNotify()
  const { buyerProfile } = useProfile()

  const [address, setAddress] = useState<Validation<BuyerShippingAddress>>({
    isValid: false,
    isLoading: true,
  })

  const fetchDefaultBuyerAddress = async (buyerProfileId: string) => {
    try {
      const result = await userService.getDefaultBuyerAddress(buyerProfileId)
      return result ?? null
    } catch (e) {
      Logger.warn('No default address found for buyer profile', e as Error)
      return null
    }
  }

  const fetchUserAddresses = async (userId: string) => {
    try {
      const result = await userService.getUserAddresses({ userId })
      return result?.items ?? []
    } catch (e) {
      Logger.warn('No default address found for buyer profile', e as Error)
      return []
    }
  }

  const createDefaultAddress = async (
    userId: string,
    buyerId: string,
    mainAddress: UserAddressForList,
  ) => {
    try {
      const result = await userService.addShippingAddress({
        addressId: mainAddress.id,
        profileId: buyerId,
        isDefault: true,
        phone: mainAddress.phone,
        userId: userId,
        type: mainAddress.type as any,
        name: mainAddress.name,
        address: mainAddress.address,
        city: mainAddress.city,
        state: mainAddress.state,
        zip: mainAddress.zip,
        country: mainAddress.country,
        isMain: true,
      } as any)

      return result ?? null
    } catch (e) {
      Logger.error(
        'Error while creating default address for buyer profile',
        undefined,
        e as Error,
      )

      return null
    }
  }

  const validateBuyerProfile = async (buyer: BuyerProfile) => {
    const defaultAddress = await fetchDefaultBuyerAddress(buyer.id)

    if (defaultAddress !== null) {
      setAddress({ isValid: true, isLoading: false, data: defaultAddress })
      return
    }

    Logger.info("Trying to create default address for buyer's profile")

    const addresses = await fetchUserAddresses(buyer.userId)

    if (addresses.length === 0) {
      Logger.warn('No addresses found for user')

      setAddress({ isValid: false, isLoading: false })

      if (params.showToastOnError) {
        info('You need a default address to proceed with the purchase.')
      }

      if (params.navigateOnError) {
        navigate('/profile/personalInformation')
      }

      return
    }

    const mainAddress = addresses.find((x) => x.isMain) ?? addresses[0]

    const createResult = await createDefaultAddress(
      buyer.userId,
      buyer.id,
      mainAddress,
    )

    if (!createResult) {
      Logger.error('Error while creating default address for buyer profile')

      setAddress({ isValid: false, isLoading: false })

      if (params.showToastOnError) {
        info('You need a default address to proceed with the purchase.')
      }

      if (params.navigateOnError) {
        navigate('/profile/personalInformation')
      }
      return
    }

    const newDefaultAddress = await fetchDefaultBuyerAddress(buyer.id)

    if (newDefaultAddress !== null) {
      setAddress({ isValid: true, isLoading: false, data: newDefaultAddress })
      return
    }

    setAddress({ isValid: false, isLoading: false })

    if (params.showToastOnError) {
      info('You need a default address to proceed with the purchase.')
    }

    if (params.navigateOnError) {
      navigate('/profile/personalInformation')
    }
  }

  useEffect(() => {
    if (!buyerProfile) return

    validateBuyerProfile(buyerProfile)
  }, [buyerProfile])

  return {
    address,
    buyerProfile,
  }
}
