import React, { memo } from 'react'
import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { AuthContextProvider } from './common/context/auth_context'
import ErrorBoundary from './components/ErrorBoundary'
import router from './routes/router'

import 'react-toastify/dist/ReactToastify.css'

const App: React.FC = memo(() => (
  <ErrorBoundary>
    <AuthContextProvider>
      <RouterProvider router={router} />
      <ToastContainer />
    </AuthContextProvider>
  </ErrorBoundary>
))
App.displayName = 'App'
export default App
