import { t } from 'i18next'
import React, { useState } from 'react'

import { Button, Icon, SlideOver } from '@/common/components'
import i18nKeys from '@/common/i18nKeys'
import Spinner from '@/components/Spinner'
import type { ShippingCostResponseInterface } from '@/core'
import { colors } from '@/theme'

import { EmptyCard } from '../listing'

import { CarrierCard } from './CarrierCard'

export type SelectCarrierProps = {
  carriers: ShippingCostResponseInterface[]
  selectedCarrier?: ShippingCostResponseInterface
  setSelectedCarrier: (e: ShippingCostResponseInterface) => void
  isLoading: boolean
  onContinue: () => void
}

export const SelectCarrier: React.FC<SelectCarrierProps> = (props) => {
  const [carrierModalOpen, setCarrierModalOpen] = useState(false)

  const handleSelectCarrier = (carrier?: ShippingCostResponseInterface) => {
    setCarrierModalOpen(false)
    if (!carrier) {
      return
    }

    props.setSelectedCarrier(carrier)
  }

  return (
    <div className="flex flex-col flex-1 justify-between">
      <div>
        <h2 className="text-2xl">
          {t(i18nKeys.buyerProcess.carrier.shipping)}
        </h2>
        {/* TODO add translations */}
        <p className="mt-2 text-gray-500 text-sm tracking-tight">
          In this step, select the shipping method you prefer to ship your
          purchased items. Please make sure the shipping method is correct to
          avoid delivery problems. Make sure you agree with the shipping costs.
        </p>

        <div className="mx-auto max-w-2xl mt-12">
          {props.selectedCarrier ? (
            <CarrierCard
              slug={props.selectedCarrier.slug}
              description={props.selectedCarrier.serviceName}
              type={props.selectedCarrier.serviceType}
              className="w-full"
              mode="card"
              price={`$${props.selectedCarrier.totalCharge}`}
              onClick={() => setCarrierModalOpen(true)}
            />
          ) : (
            <EmptyCard
              icon="home"
              text={t(i18nKeys.buyerProcess.carrier.title)}
              onClick={() => setCarrierModalOpen(true)}
            />
          )}
        </div>
      </div>
      <div className="flex justify-end mt-16">
        <Button onClick={props.onContinue} disabled={!props.selectedCarrier}>
          {t(i18nKeys.ui.continue)}
        </Button>
      </div>

      <SlideOver
        title={t(i18nKeys.buyerProcess.carrier.title)}
        onClose={() => setCarrierModalOpen(false)}
        open={carrierModalOpen}
      >
        {props.isLoading ? (
          <div className="w-full flex justify-center">
            <Spinner text={t(i18nKeys.ui.loading)} />
          </div>
        ) : (
          props.carriers.map((asset, key) => (
            <CarrierCard
              key={key}
              slug={asset.slug}
              description={asset.serviceName}
              type={asset.serviceType}
              className="w-full"
              mode="button"
              price={`$${asset.totalCharge}`}
              onClick={() => handleSelectCarrier(asset)}
            />
          ))
        )}
        {props.carriers.length === 0 && props.carriers.length > 0 && (
          <div className="text-center flex flex-col items-center">
            <Icon icon="search" size={48} color={colors.gray[300]} />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              {t(i18nKeys.buyerProcess.carrier.empty)}
            </h3>
          </div>
        )}
      </SlideOver>
    </div>
  )
}
