import type { AddressTypeEnum } from '@bit-ui-libs/common'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import type { TokenizationResponse } from '@/common/components/Paysafe/PaysafeClient'
import { authContext } from '@/common/context/auth_context'
import { useNotify } from '@/common/hooks'
import { useBuyerProfile } from '@/common/hooks/use-buyer-profile'
import { useOnlyAuthenticated } from '@/common/hooks/use-only-authenticated'
import type { BuyerShippingAddress } from '@/common/types'
import Spinner from '@/components/Spinner'
import { Logger } from '@/config'
import type { PaymentCard } from '@/core'
import { classNames, UserService } from '@/core'
import { SubscriptionService } from '@/core/subscriptions/subscriptions.service'
import type { SubscriptionDetailsResponse } from '@/core/subscriptions/subscriptions.types'

import { ChooseSubscriptionPayment } from './ChooseSubscriptionPayment'
import { purchaseSubscriptionStep } from './subscription-steps'
import { SubscriptionConfirmation } from './SubscriptionConfirmation'
import { SubscriptionStepper } from './SubscriptionStepper'

export type PaymentHandleToken = {
  profileHandleToken: string
  cardHandleToken: string
  paymentType: string
}

// TODO missing translations
export const PurchaseSubscriptionPage: React.FC = () => {
  const { authenticated } = useOnlyAuthenticated()
  const { buyerProfile } = useContext(authContext)
  const [step, setStep] = useState<number>(purchaseSubscriptionStep.payment)
  const [selectedCard, setSelectedCard] = useState<PaymentCard>()
  const [paymentHandleToken, setPaymentHandleToken] =
    useState<PaymentHandleToken>()
  const [costs, setCosts] = useState<SubscriptionDetailsResponse>()
  const { address } = useBuyerProfile()

  const { error, success } = useNotify()
  const navigate = useNavigate()

  const billingAddress = useMemo(() => {
    if (!address.data) return undefined

    return {
      address: address.data.address,
      city: address.data.city,
      state: address.data.state,
      country: address.data.country,
      zip: address.data.zip,
      name: address.data.name,
      type: address.data.type as unknown as AddressTypeEnum,
      id: address.data.id,
      isDefault: address.data.isDefault,
      isMain: address.data.isMain,
      phone: address.data.phone,
      profileId: buyerProfile?.id!,
      userId: buyerProfile?.userId!,
      shipInternationally: address.data.shipInternationally,
      addressLine1: address.data.addressLine1,
      addressLine2: address.data.addressLine2,
      countryCode: address.data.country,
      isAddressMatched: address.data.isAddressMatched,
    } as BuyerShippingAddress
  }, [address])

  const handleConfirmPurchase = async (result: TokenizationResponse) => {
    try {
      if (!result) {
        return
      }

      await SubscriptionService.processPayment({
        merchantRefNum: result.merchantRefNum,
        paymentHandleToken: result.paymentHandleToken,
        period: costs!.period,
      })

      success('Subscribed successfully!')

      navigate('/profile/digitalIdentity')
    } catch (e) {
      Logger.error('Error while validating the payment', undefined, e as Error)
      error('Error while validating the payment')
    }
  }

  const handleFetchSingleUseToken = async () => {
    try {
      if (!selectedCard) {
        return
      }

      const userService = new UserService()
      const singleUseToken = await userService.getSingleUseToken(
        buyerProfile?.id!,
      )

      // TODO: the backend returns all payment handles without cardId, we need to filter the selected one
      const found = singleUseToken.paymentHandles.find(
        (x) =>
          x.card.lastDigits === selectedCard.lastDigits &&
          x.card.holderName === selectedCard.holderName &&
          x.card.type === selectedCard.type &&
          +x.card.cardExpiry.month === selectedCard.cardExpiry.month &&
          +x.card.cardExpiry.year === selectedCard.cardExpiry.year,
      )

      if (!found) {
        throw new Error('Card not found')
      }

      setPaymentHandleToken({
        profileHandleToken: singleUseToken.singleUseCustomerToken,
        cardHandleToken: found.paymentHandleToken,
        paymentType: found.paymentType ?? 'CARD',
      })
    } catch (e) {
      Logger.error('Error while loading payment details', undefined, e as Error)
      error('Error while loading payment details')
    }
  }

  const handleFetchPaymentDetails = async () => {
    try {
      const data = await SubscriptionService.getPaymentData()
      setCosts(data)
    } catch (e) {
      Logger.error(
        'Error while fetching payment details',
        undefined,
        e as Error,
      )
      error('Error while fetching payment details')
    }
  }

  const handleGoBack = () => {
    setStep(step - 1)
  }

  useEffect(() => {
    if (!selectedCard) return

    handleFetchSingleUseToken()
  }, [selectedCard])

  useEffect(() => {
    if (!address.data) return

    handleFetchPaymentDetails()
  }, [address])

  if (!authenticated || address.isLoading) {
    return (
      <div className="min-h-[80vh]">
        <section
          className={classNames(
            'mx-auto max-w-7xl sm:px-2 lg:px-8 bg-white transition-all duration-300 ease-in-out',
            !authenticated
              ? 'flex items-center justify-center py-12 sm:py-48 mb-12'
              : 'py-6 sm:py-8',
          )}
        >
          <div className="mx-auto max-w-2xl px-4 lg:max-w-4xl lg:px-0 flex flex-col">
            {!authenticated ||
              (address.isLoading && (
                <Spinner containerClassName="flex items-center justify-center mt-6" />
              ))}
          </div>
        </section>
      </div>
    )
  }

  return (
    <div className="w-full flex flex-col sm:grid sm:grid-cols-5 gap-2">
      <div className="sm:py-3 sm:px-6 sm:col-span-1">
        <SubscriptionStepper step={step} />
      </div>
      <div className="py-3 sm:px-6 sm:col-span-3">
        {step === purchaseSubscriptionStep.payment && (
          <ChooseSubscriptionPayment
            shippingAddress={billingAddress}
            selectedCard={selectedCard}
            onSelectCard={setSelectedCard}
            onPaymentComplete={() => {}}
            onPaymentFailed={() => {}}
            handleToken={paymentHandleToken}
            setStep={setStep}
          />
        )}

        {step === purchaseSubscriptionStep.confirm && (
          <SubscriptionConfirmation
            price={Number(costs?.amountPaysafe)}
            priceDisplay={`$${costs?.amount}`}
            selectedCard={selectedCard!}
            billingAddress={billingAddress!}
            onBack={handleGoBack}
            onConfirm={handleConfirmPurchase}
            paymentHandleToken={paymentHandleToken!}
          />
        )}
      </div>
      <div className="py-3 sm:px-6 sm:col-span-1"></div>
    </div>
  )
}
