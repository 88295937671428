import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/common/components'
import { sellItemsRoutes } from '@/routes'
import { sellServiceRoutes } from '@/routes/sell-service-routes'

import type { CardOption } from '..'
import { OptionCards, SellPage } from '..'
import { listingTypeOptions } from '../constants/listing-type'
import { ListingType } from '../types/listing-type'

export default function InitListingPage() {
  const navigate = useNavigate()

  const [selectedOption, setSelectedOption] = useState<CardOption>(
    listingTypeOptions[0],
  )

  const handleNavigate = (backwards: boolean = false) => {
    if (backwards) {
      window.history.back()
      return
    }

    const selected = selectedOption.id as ListingType

    if (selected === ListingType.Asset) {
      navigate(`${selected}/${sellItemsRoutes[0].route.path!}`)
      return
    }

    navigate(`${selected}/${sellServiceRoutes[0].route.path!}`)
  }

  return (
    <div className="flex">
      <main className="flex flex-col flex-[6] min-w-80 px-6 pb-8">
        <div className="max-w-[900px] self-center w-full">
          <SellPage title="I want to sell something on the Marketplace">
            <OptionCards
              options={listingTypeOptions}
              selected={selectedOption}
              onChange={setSelectedOption}
              className="flex gap-2 sm:gap-8 w-full mt-8"
            />
            <div className="flex justify-end gap-7 w-full absolute bottom-0 px-6 py-5">
              <Button onClick={() => handleNavigate(true)} mode="text">
                Cancel
              </Button>
              <Button onClick={() => handleNavigate()}>Continue</Button>
            </div>
          </SellPage>
        </div>
      </main>
    </div>
  )
}
