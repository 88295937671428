import React from 'react'

import { SellPage, PageActionButtons, OptionCards } from '..'
import { serviceListingTerms } from '../constants'
import { useServiceListing } from '../context/service-listing-context'

export default function ServiceTermsPage() {
  const terms = useServiceListing((state) => state.completeness)
  const update = useServiceListing((state) => state.update)

  return (
    <SellPage>
      <OptionCards
        className="flex flex-col sm:flex-row gap-2 sm:gap-8 w-full mt-8"
        options={serviceListingTerms}
        selected={terms}
        onChange={(v) => update('completeness', v)}
      />
      <PageActionButtons />
    </SellPage>
  )
}
