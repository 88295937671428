import type { CardOption } from '..'
import { TaxCategoryType } from '../types/taxes'

export const taxCategories: CardOption[] = [
  {
    id: TaxCategoryType.SpecialTaxCategory,
    title: 'Special Tax Category',
    description: 'Special tax category is applied to specific items.',
    action: 'Recommended',
    actionClassName: 'text-primary italic',
  },
  {
    id: TaxCategoryType.GeneralTaxes,
    title: 'Sales Tax',
    description: 'General taxes are applied to all items.',
    disabled: false,
  },
]
