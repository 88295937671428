import { t } from 'i18next'
import { isUndefined } from 'lodash'
import React from 'react'

import { classNames } from '@/core'

import type { IconSource } from '../common/components'
import { Icon } from '../common/components'
import i18nKeys from '../common/i18nKeys'
import { colors } from '../theme'

import { ConditionalRendering } from './ConditionalRendering'

interface IconButtonConfiguration {
  onClick: () => void
  title?: string
}

interface SectionFieldProps {
  defaultIcon?: IconButtonConfiguration
  deleteIcon?: IconButtonConfiguration
  editIconTitle?: string
  icon: IconSource
  info?: string
  infoCompleted: boolean
  isDefault?: boolean
  labelI18nKey: string
  onClick?: () => void
  placeholderI18nKey: string
  isDisabled?: boolean
}

const ActionsSection: React.FC<SectionFieldProps> = (props) => {
  if (!props.infoCompleted) {
    return (
      <button
        className="text-white bg-gray-500 p-[10px] font-medium"
        onClick={props.onClick}
        disabled={props.isDisabled}
      >
        {t(i18nKeys.ui.add)}
      </button>
    )
  }

  const handleDefaultIconClick = () => {
    if (props.defaultIcon && !props.isDefault) {
      props.defaultIcon.onClick()
    }
  }
  // TODO missing translations for the title
  return (
    <div className="flex">
      <ConditionalRendering renderIf={!isUndefined(props.defaultIcon)}>
        <div className="m-auto" title="Mark as your main address">
          <Icon
            onClick={handleDefaultIconClick}
            icon={props.isDefault ? 'starFilled' : 'star'}
            className="my-auto mx-3 text-lg cursor-pointer"
            title={props.defaultIcon?.title}
          />
        </div>
      </ConditionalRendering>

      <ConditionalRendering renderIf={!isUndefined(props.onClick)}>
        <Icon
          icon="edit"
          className="my-auto mx-0 text-lg cursor-pointer"
          onClick={props.onClick}
          title={props.editIconTitle}
        />
      </ConditionalRendering>

      <ConditionalRendering renderIf={!isUndefined(props.deleteIcon)}>
        <Icon
          icon="trash"
          className="my-auto mx-3 text-lg cursor-pointer"
          onClick={props.deleteIcon?.onClick}
          title={props.deleteIcon?.title}
        />
      </ConditionalRendering>
    </div>
  )
}

export const SectionField: React.FC<SectionFieldProps> = (props) => {
  return (
    <div className="border-2 border-gray-500 min-h-6 flex justify-between p-2 mb-4">
      <div className="flex">
        <div
          className={classNames(
            'border rounded-[50%] size-12 flex items-center justify-center me-5',
            props.infoCompleted ? 'text-white' : 'border-black',
          )}
          style={{
            backgroundColor: props.infoCompleted
              ? colors.primary.DEFAULT
              : 'white',
            color: props.infoCompleted ? colors.primary.DEFAULT : 'black',
          }}
        >
          <Icon icon={props.icon} color={'black'} />
        </div>
        <div className="w-[200px]">
          <p className="font-bold">{t(props.labelI18nKey)}</p>
          {props.infoCompleted ? (
            <p className="text-ellipsis overflow-hidden whitespace-nowrap">
              {props.info}
            </p>
          ) : (
            <p>{t(props.placeholderI18nKey)}</p>
          )}
        </div>
      </div>
      <ActionsSection {...props} />
    </div>
  )
}
