import { t } from 'i18next'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import type { TokenizationResponse } from '@/common/components/Paysafe/PaysafeClient'
import { authContext } from '@/common/context/auth_context'
import { useNotify } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import type { BuyerShippingAddress } from '@/common/types'
import { Logger } from '@/config'
import type { PaymentCard } from '@/core'
import { UserService } from '@/core'
import { AddPaymentCardStep } from '@/features/buyProcess/AddPaymentCardStep'
import { BuyPage } from '@/features/buyProcess/BuyPage'
import { SelectCard } from '@/features/buyProcess/SelectCard'

import type { PaymentHandleToken } from './PurchaseSubscriptionPage'
import { purchaseSubscriptionStep } from './subscription-steps'

export interface ChooseSubscriptionPaymentProps {
  shippingAddress?: BuyerShippingAddress
  selectedCard?: PaymentCard
  handleToken?: PaymentHandleToken
  onSelectCard: (card: PaymentCard) => void
  onPaymentComplete?: (result: TokenizationResponse) => void | Promise<void>
  onPaymentFailed?: () => void | Promise<void>
  setStep: (e: number) => void
}

// TODO missing translations
export const ChooseSubscriptionPayment: React.FC<
  ChooseSubscriptionPaymentProps
> = ({
  shippingAddress,
  selectedCard,
  handleToken,
  onSelectCard,
  onPaymentComplete,
  onPaymentFailed,
  setStep,
}) => {
  const { profile, buyerProfile } = useContext(authContext)
  const { error } = useNotify()
  const [cards, setCards] = useState<PaymentCard[]>()
  const [isAddCard, setIsAddCard] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  async function fetchCards() {
    try {
      const usersService = new UserService()
      const _cards = await usersService.listCards(buyerProfile?.id!)
      setCards(_cards)
    } catch (e) {
      Logger.error('Failed to fetch cards', undefined, e as Error)
      setCards([])
    }
  }

  const handleAddCard = async (result: TokenizationResponse) => {
    try {
      if (isLoading) {
        return
      }

      setIsLoading(true)

      const userService = new UserService()
      const createdCard = await userService.createCard(buyerProfile?.id!, {
        isDefault: false,
        profileId: buyerProfile?.id!,
        paymentToken: result.paymentHandleToken,
      })

      setCards([...cards!, createdCard])
      onSelectCard(createdCard)
      setIsAddCard(false)
      setStep(purchaseSubscriptionStep.confirm)
    } catch (e) {
      Logger.error('Failed to add a card', undefined, e as Error)
      error('Failed to add a card.')
    } finally {
      setIsLoading(false)
    }
  }

  const cardHolderName = useMemo(() => {
    if (!profile) return undefined

    const segments = [profile.firstName, profile.middleName, profile.lastName]

    return segments.filter(Boolean).join(' ')
  }, [profile?.firstName, profile?.middleName, profile?.lastName])

  useEffect(() => {
    if (buyerProfile && !cards) {
      fetchCards()
    }
  }, [buyerProfile])

  return (
    <BuyPage
      heading={t(i18nKeys.buyerProcess.confirmationSection.payment)}
      description="Choose a payment method for your purchase. Ensure the card details are accurate. For your security, all transactions are encrypted and we do not store your card information." // TODO add translation
    >
      {isAddCard ? (
        <AddPaymentCardStep
          profile={profile!}
          shippingAddress={shippingAddress}
          onComplete={handleAddCard}
          totalPrice={0}
          onBack={() => setIsAddCard(false)}
        />
      ) : (
        <SelectCard
          selectedCard={selectedCard}
          cards={cards}
          onSelectCard={onSelectCard}
          onCreateCard={() => setIsAddCard(true)}
          billingAddress={shippingAddress!}
          cardHolderName={cardHolderName!}
          totalPrice={0}
          onPaymentComplete={onPaymentComplete}
          onPaymentFailed={onPaymentFailed}
          handleToken={handleToken}
          onContinue={() => setStep(purchaseSubscriptionStep.confirm)}
        />
      )}
    </BuyPage>
  )
}
