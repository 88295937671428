import type { Device, DeviceVdt } from '@bit-ui-libs/common'
import { t } from 'i18next'
import jwtDecode from 'jwt-decode'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { Button } from '@/common/components'
import { IncodeIntegration } from '@/common/components/Incode/IncodeIntegration'
import styles from '@/common/components/Incode/IncodeStyles.module.css'
import type { UserProfileType } from '@/common/context/auth_context'
import { authContext } from '@/common/context/auth_context'
import { ConditionalRendering } from '@/components'
import { SectionField } from '@/components/SectionField'
import Spinner from '@/components/Spinner'
import type { SessionToken } from '@/core'
import { DEVICE_IS_PERSONAL_DEFAULT_VALUE, DeviceService } from '@/core/devices'
import {
  getDeviceModel,
  getDeviceOSName,
  getUniqueDeviceName,
} from '@/core/devices/device-utils'
import { getDeviceLocation } from '@/core/location'
import { UserService } from '@/core/users'
import { Web3Service } from '@/core/web3'
import type { AddressInformationFormValues } from '@/pages'
import { PersonalInformationCompleted } from '@/pages'

import { OnboardingStepper } from '..'
import envVariables from '../../../common/envVariables'
import i18nKeys from '../../../common/i18nKeys'
import ApplicationStorage from '../../../core/storage'
import ChainITLogo from '../../../resources/chaiItLogo.png'
import VectorImage from '../../../resources/Vector.png'

import AddressInformation from './AddressInformation'
import Information from './Information'
import WalletInformation from './WalletInformation'

import { PhoneInformation } from '.'

const FACE_SCAN_STEP = 2
const PERSONAL_INFO_STEP = 3
const ONBOARDING_STEP = 4
const ONBOARDING_COMPLETED_STEP = 5
const BIT_TOKEN_KEY = 'bitToken'
interface ContinueButtonProps {
  show: boolean
  onClick: () => void
}
const ContinueButton: React.FC<ContinueButtonProps> = ({ show, onClick }) => {
  return (
    <ConditionalRendering renderIf={show}>
      <Button className="w-full py-2 px-8" onClick={onClick}>
        {t(i18nKeys.ui.continue)}
      </Button>
    </ConditionalRendering>
  )
}

// TODO this component should be split into smaller components
// TODO hardcoded colors are user. Change them for theme colors.
// TODO hardcoded widhts and heights are used. Change them for theme sizes or something more adjustable to the screen size.
// TODO move all device related logic to a hook "useDevice" or something like that
export function PersonalInformationPage() {
  const [steps, setSteps] = useState<number>(FACE_SCAN_STEP)
  const [showPersonalInfoForm, setShowPersonalInfoForm] =
    useState<boolean>(false)
  const [showPhoneForm, setShowPhoneForm] = useState<boolean>(false)
  const [openIncodeQr, setOpenIncodeQr] = useState<boolean>(false)
  const [showAddressForm, setShowAddressForm] = useState<boolean>(false)
  const [showWalletForm, setShowWalletForm] = useState<boolean>(false)
  const [cameraRenderType, setCameraRenderType] = useState<string>('')
  const navigate = useNavigate()
  const bitToken = localStorage.getItem(BIT_TOKEN_KEY)

  const {
    phone,
    mainAddress,
    setUserProfile,
    profile,
    profileLoaded,
    walletAddress,
    reloadMainAddress,
    setWalletAddress,
    userVdt,
  } = useContext(authContext)

  const goToNextStep = useCallback(() => {
    setSteps((prev) => prev + 1)
  }, [setSteps])

  const registerDevice = useCallback(
    async (latitude: number, longitude: number) => {
      return DeviceService.registerDevice({
        externalId: uuid(),
        latitude,
        longitude,
        meanSeaLevel: 0, // TODO can we get this value? the current Geolocation API does not support it.
        ownerUserId: profile?.userId!,
        recipientAddress: walletAddress!,
        model: getDeviceModel(),
        name: getUniqueDeviceName(),
        operativeSystem: getDeviceOSName(),
      })
    },
    [walletAddress, profile],
  )

  useEffect(() => {
    if (profile?.mainProfileCompleted) {
      navigate('/')
    }
  }, [profileLoaded])

  const initUserVdt = useCallback(
    async (device: Device) => {
      return Web3Service.initUserVdt({
        deviceId: device.id,
        latitude: device.latitude ?? 0,
        longitude: device.longitude ?? 0,
        meanSeaLevel: device.meanSeaLevel ?? 0,
        recipientAddress: walletAddress!,
      })
    },
    [walletAddress],
  )

  const fullName = useMemo(() => {
    let result = ''
    if (profile?.firstName) {
      result = `${profile?.firstName} `
    }

    if (profile?.middleName) {
      result = result + `${profile?.middleName} `
    }

    if (profile?.lastName) {
      result = result + `${profile?.lastName}`
    }

    return result
  }, [profile])

  const getDeviceStatus = useCallback(async () => {
    const devices = await DeviceService.searchDevices({
      isPersonal: DEVICE_IS_PERSONAL_DEFAULT_VALUE,
      name: getUniqueDeviceName(),
      userId: profile?.userId!,
      // TODO add orgId filter when available
    })

    const deviceExists = devices.totalCount > 0
    let deviceVdt: DeviceVdt | null = null

    if (deviceExists) {
      // Verify if it is minted
      const firstDevice = devices.items[0]
      deviceVdt = await Web3Service.getDeviceVdt(firstDevice.id)
    }

    return {
      deviceExists,
      vdtExists: deviceVdt !== null,
      device: deviceExists ? devices.items[0] : null,
    }
  }, [profile])

  const mintDevice = useCallback(async (device: Device) => {
    DeviceService.initDeviceVdt({
      deviceId: device.id,
      latitude: device.latitude ?? 0,
      longitude: device.longitude ?? 0,
      meanSeaLevel: device.meanSeaLevel ?? 0,
      recipientAddress: walletAddress!,
    })
  }, [])

  const completeOnboarding = useCallback(async () => {
    // TODO handle errors
    const deviceLocation = await getDeviceLocation()

    const deviceStatus = await getDeviceStatus()
    if (!deviceStatus.deviceExists || !deviceStatus.device) {
      deviceStatus.device = await registerDevice(
        deviceLocation.coords.latitude,
        deviceLocation.coords.longitude,
      )
      deviceStatus.deviceExists = true
    }

    if (!deviceStatus.vdtExists) {
      await mintDevice(deviceStatus.device)
      deviceStatus.vdtExists = true
    }

    if (!userVdt) {
      await initUserVdt(deviceStatus.device)
    }
    goToNextStep()
  }, [getDeviceStatus, registerDevice, mintDevice, initUserVdt, userVdt])

  useEffect(() => {
    if (steps === ONBOARDING_STEP) {
      completeOnboarding()
    }
  }, [steps, completeOnboarding])

  useEffect(() => {
    // do we need this?
    // Check if the user has already completed the biometric verification
    const sessionToken = ApplicationStorage.sessionToken
    if (sessionToken && !profileLoaded) {
      const decodedSessionToken = jwtDecode(sessionToken!) as SessionToken
      if (decodedSessionToken.biometricInfo) {
        setSteps(PERSONAL_INFO_STEP)
      }
    }
  }, [])

  useEffect(() => {
    if (profileLoaded) {
      setSteps(PERSONAL_INFO_STEP)
    }
  }, [profileLoaded])

  const submitInformationForm = (event: any) => {
    event.preventDefault()
    if (profileLoaded && profile) {
      const data: UserProfileType = {
        ...profile,
        firstName: event.target['firstName'].value,
        lastName: event.target['lastName'].value,
        middleName: event.target['middleName'].value,
      }
      const userService = new UserService()
      userService.updateUserInfo(data, profile.userId)
      // TODO what if updating fails? we are updating the context anyway
      setUserProfile(data)
      setShowPersonalInfoForm(false)
    }
  }

  const submitAddressForm = async (values: AddressInformationFormValues) => {
    if (profileLoaded && profile) {
      const userService = new UserService()

      // On this screen, we only set or update the main address
      const request = {
        userId: profile.userId,
        type: values.type,
        name: values.name,
        address: values.addressLine1,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        city: values.city,
        state: values.state,
        zip: values.zip,
        country: values.country,
        phone: phone || '',
        isMain: true,
      }
      // TODO handle errors
      if (mainAddress) {
        await userService.updateUserAddress(mainAddress.id, {
          id: mainAddress.id,
          ...request,
        } as any)
      } else {
        await userService.addUserAddress(request as any)

        //We keep this commented code in case the workflow changes again

        // let buyerProfile = await userService.getListUserBuyerProfiles(
        //   {
        //     userId: profile?.userId,
        //     appName: envVariables.APP_NAME,
        //     isDefault: true,
        //   }
        // )

        // if (buyerProfile.length === 0) {
        //   let result = await userService.addBuyerProfile({
        //     userId: profile?.userId,
        //     appName: envVariables.APP_NAME,
        //     isDefault: true,
        //   })
        //   buyerProfile.push(result)
        // }

        // const data = {
        //   profileId: buyerProfile[0]?.id,
        //   addressId: result.id,
        //   phoneNumber: profile?.phone ? profile?.phone : '',
        //   shipInternationally: true,
        //   ...result,
        // } as AddShippingAddressRequest

        // let shippingAddressResult = await userService.addShippingAddress(data)
      }

      reloadMainAddress()
      setShowAddressForm(false)
    }
  }

  const personalInfoCompleted = profile?.firstName && profile?.lastName
  const completed = !!personalInfoCompleted && !!mainAddress && !!phone
  let bodyContent = null
  let sectionSubtitle = null
  let section = null

  if (steps === FACE_SCAN_STEP) {
    if (openIncodeQr) {
      sectionSubtitle =
        cameraRenderType === 'desktop' ? (
          <p>
            {t(
              i18nKeys.onboarding.personalInformation
                .allowBrowserToAccessCamera,
            )}
          </p>
        ) : (
          <p>
            {t(i18nKeys.onboarding.personalInformation.scanCodeInAnotherDevice)}
          </p>
        )
      section = (
        <IncodeIntegration
          nextStep={goToNextStep}
          onOnboardingComplete={() => setOpenIncodeQr(false)}
          onClose={() => setOpenIncodeQr(false)}
          renderType={cameraRenderType}
          incodeFlowId={envVariables.INCODE_ONBOARDING_FLOW_ID}
        />
      )
    } else {
      sectionSubtitle = (
        <p>
          {t(
            i18nKeys.onboarding.personalInformation
              .scanYourFaceToVerifyYourIdentity,
          )}
        </p>
      )
      section = (
        <>
          <img className="m-auto" src={VectorImage} alt=""></img>

          <div className="text-center absolute bottom-20 w-[calc(100%-20px)]">
            <div>
              <button
                className="bg-[#7fc241] py-[10px] px-8 w-100 text-lg text-white rounded-lg"
                onClick={() => {
                  setCameraRenderType('desktop')
                  setOpenIncodeQr(true)
                }}
              >
                {t(
                  i18nKeys.onboarding.personalInformation.continueOnThisDevice,
                )}
              </button>
            </div>
            <button
              className="text-[#7fc241] m-3 text-lg"
              onClick={() => {
                setCameraRenderType('mobile')
                setOpenIncodeQr(true)
              }}
            >
              {t(
                i18nKeys.onboarding.personalInformation.continueOnAnotherDevice,
              )}
            </button>
          </div>
        </>
      )
    }
  } else if (steps === PERSONAL_INFO_STEP) {
    if (showPersonalInfoForm) {
      sectionSubtitle = (
        <p>{t(i18nKeys.onboarding.personalInformation.tellUsAboutYou)}</p>
      )
      section = (
        <Information
          onSave={submitInformationForm}
          onCancel={() => setShowPersonalInfoForm(false)}
          defaultValues={{
            firstName: profile?.firstName ?? '',
            middleName: profile?.middleName ?? '',
            lastName: profile?.lastName ?? '',
          }}
        />
      )
    } else if (showPhoneForm) {
      sectionSubtitle = (
        <p>{t(i18nKeys.onboarding.personalInformation.howToReachYou)}</p>
      )
      section = (
        <PhoneInformation
          onClose={() => setShowPhoneForm(false)}
          defaultValue={phone ?? ''}
        />
      )
    } else if (showAddressForm) {
      sectionSubtitle = (
        <p>{t(i18nKeys.onboarding.personalInformation.whereToFindYou)}</p>
      )
      section = (
        <AddressInformation
          defaultValues={mainAddress ?? {}}
          onClose={() => setShowAddressForm(false)}
          onSave={submitAddressForm}
        />
      )
    } else if (showWalletForm) {
      // TODO This section is going to be removed in the future when ChainIT Pay is integrated
      section = (
        <WalletInformation
          onClose={() => setShowWalletForm(false)}
          onSave={(response: any) => {
            setShowWalletForm(false)
            setWalletAddress(response.address)
          }}
        />
      )
    } else {
      sectionSubtitle = (
        <p>
          {t(
            i18nKeys.onboarding.personalInformation
              .toContinueTellUsAboutYourself,
          )}
        </p>
      )
      section = (
        <>
          <SectionField
            icon="user"
            infoCompleted={!!personalInfoCompleted}
            labelI18nKey={
              i18nKeys.onboarding.personalInformation.personalInformation
            }
            onClick={() => setShowPersonalInfoForm(true)}
            placeholderI18nKey={
              i18nKeys.onboarding.personalInformation.tellUsAboutYou
            }
            info={fullName}
          />

          <SectionField
            icon="phone"
            infoCompleted={!!phone}
            labelI18nKey={
              i18nKeys.onboarding.personalInformation.contactDetails
            }
            onClick={() => setShowPhoneForm(true)}
            placeholderI18nKey={
              i18nKeys.onboarding.personalInformation.howToReachYou
            }
            info={phone ?? ''}
            isDisabled={!(profile?.firstName && profile?.lastName)}
          />

          <SectionField
            icon="home"
            infoCompleted={mainAddress?.id !== undefined}
            labelI18nKey={i18nKeys.onboarding.personalInformation.location}
            onClick={() => setShowAddressForm(true)}
            placeholderI18nKey={
              i18nKeys.onboarding.personalInformation.whereToFindYou
            }
            info={mainAddress?.address ?? ''}
            isDisabled={!profile?.phoneVerified}
          />

          <div className="flex bottom-4">
            <ContinueButton
              show={completed}
              onClick={() => {
                // goToNextStep()
                navigate('/wallet')
              }}
            />
          </div>
        </>
      )
    }
  }

  if (!profileLoaded && bitToken !== null) {
    bodyContent = (
      <div>
        <div className="w-full p-6">
          <Spinner
            text={t(i18nKeys.onboarding.verifyingInformation)}
            textClassName="text-sm mt-3 text-center"
            className="m-auto"
          />
        </div>
      </div>
    )
  } else {
    if (steps === FACE_SCAN_STEP) {
      bodyContent = (
        <>
          <div className="text-center w-full mb-8">
            <img
              src={ChainITLogo}
              className="m-auto h-[60px] mb-6"
              alt="ChainIT logo"
            />
            <h2 className="font-medium text-2xl mb-4">{profile?.firstName}</h2>
            {sectionSubtitle}
          </div>
          {section}
        </>
      )
    } else if (steps === PERSONAL_INFO_STEP) {
      bodyContent = (
        <>
          <img
            src={ChainITLogo}
            className="m-auto h-[60px] mb-6"
            alt="ChainIT logo"
          />
          <div className="text-center w-full mb-8">
            <h2 className="font-medium text-2xl mb-4">
              {t(i18nKeys.onboarding.personalInformation.personalInfo)}
            </h2>
            {sectionSubtitle}
          </div>
          {section}
        </>
      )
    } else if (steps === ONBOARDING_COMPLETED_STEP) {
      bodyContent = <PersonalInformationCompleted />
    } else if (steps === ONBOARDING_STEP) {
      bodyContent = <p>Minting</p>
    } else {
      bodyContent = (
        <p>{t(i18nKeys.onboarding.personalInformation.goBackAndLoginAgain)}</p>
      )
    }
  }

  return (
    <div className="min-h-screen">
      {steps < ONBOARDING_COMPLETED_STEP && (
        <OnboardingStepper initialStep={steps} />
      )}
      <div className="min-h-[674px] min-w-[432px] h-fit w-fit my-0 mx-auto p-5 rounded-xl bg-[#f9f9f9] relative mb-5">
        {bodyContent}
        <div
          id="mobile-camera-container"
          style={openIncodeQr ? { display: 'block' } : { display: 'none' }}
          className={styles.incodeQrContainer}
        />
      </div>
    </div>
  )
}

PersonalInformationPage.displayName = 'PersonalInformation'
