import React from 'react'
import { createBrowserRouter } from 'react-router-dom'

import { ComingSoon } from '@/common/components/display/ComingSoon'
import { PublicAssetInformation } from '@/features/assets/screens/PublicAssetInformation'
import { SearchResult } from '@/features/assets/screens/SearchResult'
import { BuyProcess, OrderConfirmationPage } from '@/features/buyProcess'
import { CartAndWishlistPage } from '@/features/cart/screens/CartAndWishlistPage'
import { CategoryStorefrontPage } from '@/features/categories/pages/CategoryStorefrontPage'
import { AuctionHistoryPage } from '@/features/listing/pages/AuctionHistoryPage'
import InitListingPage from '@/features/listing/pages/InitListingPage'
import { CustomerAssetInformation } from '@/features/profile/myLibrary/CustomerAssetInformation'
import { PurchaseSubscriptionPage } from '@/features/profile/subscription/PurchaseSubscriptionPage'
import { StorefrontPage } from '@/features/storefront'
import {
  Biometric,
  Notfound,
  PersonalInformationPage,
  Signout,
  Verification,
  Wallet,
} from '@/pages'

import AssetDetail from '../features/assets/screens/AssetDetail'
import { HomePage } from '../features/home'
import { ListingLayout } from '../features/listing'
import { Profile } from '../features/profile'
import { TestingComponent } from '../features/testingComponent/TestingComponent'
import EmptyLayout from '../layouts/main/EmptyLayout'
import MainLayout from '../layouts/main/MainLayout'
import SimpleLayout from '../layouts/simple/SimpleLayout'

import { PrivateRoute } from './PrivateRoute'
import { profileRoutes } from './profile-routes'
import { sellServiceRoutes } from './sell-service-routes'

import { sellItemsRoutes } from '.'

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <MainLayout>
        <HomePage />
      </MainLayout>
    ),
  },
  {
    path: '/search',
    element: (
      <MainLayout>
        <SearchResult />
      </MainLayout>
    ),
  },
  {
    path: '/asset/:assetId/detail',
    element: (
      <MainLayout>
        <AssetDetail />
      </MainLayout>
    ),
  },
  {
    path: '/cart',
    element: (
      <MainLayout>
        <CartAndWishlistPage />
      </MainLayout>
    ),
  },
  {
    path: '/sell',
    element: (
      <SimpleLayout>
        <InitListingPage />
      </SimpleLayout>
    ),
  },
  {
    path: '/sell/:type',
    element: (
      <SimpleLayout>
        <ListingLayout />
      </SimpleLayout>
    ),
    children: [...sellItemsRoutes, ...sellServiceRoutes].map((r) => r.route),
  },
  {
    path: '/personalInformation',
    element: (
      <SimpleLayout>
        <PersonalInformationPage />
      </SimpleLayout>
    ),
  },
  {
    path: '/biometrics',
    element: (
      <SimpleLayout>
        <Biometric />
      </SimpleLayout>
    ),
  },
  {
    path: '/onboarding/login/verification',
    element: (
      <SimpleLayout>
        <Verification />
      </SimpleLayout>
    ),
  },
  {
    path: '/signout',
    element: (
      <EmptyLayout>
        <Signout />
      </EmptyLayout>
    ),
  },
  {
    path: '/local-testing',
    element: (
      <EmptyLayout>
        <TestingComponent />
      </EmptyLayout>
    ),
  },
  {
    path: '*',
    element: (
      <MainLayout>
        <Notfound />
      </MainLayout>
    ),
  },
  {
    path: '/profile',
    element: (
      <PrivateRoute>
        <MainLayout>
          <Profile />
        </MainLayout>
      </PrivateRoute>
    ),
    children: profileRoutes.map((r) => r.route),
  },
  {
    path: '/buyProcess/:listingId',
    element: (
      <MainLayout>
        <BuyProcess />
      </MainLayout>
    ),
  },
  {
    path: '/search/listing/:listingId/event/:eventIdRef/detail',
    element: (
      <MainLayout>
        <PublicAssetInformation />
      </MainLayout>
    ),
  },
  {
    path: '/myLibrary/asset/:assetId/detail',
    element: (
      <MainLayout>
        <CustomerAssetInformation />
      </MainLayout>
    ),
  },
  {
    path: 'orderConfirmation/:orderId',
    element: (
      <SimpleLayout>
        <OrderConfirmationPage />
      </SimpleLayout>
    ),
  },
  {
    path: '/coming-soon',
    element: (
      <MainLayout>
        <ComingSoon />
      </MainLayout>
    ),
  },
  {
    path: '/subcategory/:assetTypeId',
    element: (
      <MainLayout>
        <SearchResult />
      </MainLayout>
    ),
  },
  {
    path: '/category/:categoryId',
    element: (
      <MainLayout>
        <SearchResult />
      </MainLayout>
    ),
  },
  {
    path: '/products',
    element: (
      <MainLayout>
        <CategoryStorefrontPage category="product" />
      </MainLayout>
    ),
  },
  {
    path: '/auctionTracking',
    element: (
      <MainLayout>
        <AuctionHistoryPage />
      </MainLayout>
    ),
  },
  {
    path: '/subscription',
    element: (
      <MainLayout>
        <PurchaseSubscriptionPage />
      </MainLayout>
    ),
  },
  {
    path: '/wallet',
    element: (
      <SimpleLayout>
        <Wallet />
      </SimpleLayout>
    ),
  },
  {
    path: '/store/:shortName',
    element: (
      <MainLayout>
        <StorefrontPage />
      </MainLayout>
    ),
  },
])
// There is a bug on react router + vite. Workaround:
// https://github.com/remix-run/react-router/issues/9799#issuecomment-1974256554
export default router
