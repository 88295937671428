import React from 'react'

import { Icon } from '..'
import { colors } from '../../../theme'

export interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  /**
   * Error / validation message. Null to not show an error
   */
  error?: string

  /**
   * Input label
   */
  label?: string

  rightText?: string
}

/**
 * Text input component, intended to wrap an implementation or library component
 */
export function Input(props: InputProps) {
  return (
    <div className={props.className}>
      {!!props.label && (
        <label
          htmlFor={props.id}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {props.label}
        </label>
      )}
      <div className="relative mt-2 rounded-md focus:shadow-sm">
        <input
          {...props}
          maxLength={props.maxLength ?? 255}
          className={`block w-full rounded-md border-0 py-1.5 pr-2 sm:text-sm sm:leading-6 ring-1 ring-inset focus:ring-2 focus:ring-inset ${!props.error ? 'text-gray-900 focus:shadow-sm ring-gray-300 placeholder:text-gray-400 focus:ring-primary' : 'text-red-900  ring-red-300 placeholder:text-red-300 focus:ring-red-500'} ${props.className || ''}`}
        />
        {props.rightText ? (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span className="text-gray-500 sm:text-sm">{props.rightText}</span>
          </div>
        ) : null}
        {!!props.error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <Icon icon="error" size={20} color={colors.error} />
          </div>
        )}
      </div>
      {!!props.error && (
        <p
          className="mt-2 text-sm text-red-600"
          id={`${props.id ?? props.name}-error`}
        >
          {props.error}
        </p>
      )}
    </div>
  )
}
