import type { DeviceData } from '@bit-ui-libs/common'
import { ReferenceType, StepEnum } from '@bit-ui-libs/common'
import { t } from 'i18next'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'

import type { ComboboxOption } from '@/common/components'
import { Combobox, Input } from '@/common/components'
import { ErrorLabel } from '@/common/components/display'
import { Identifiers } from '@/common/constants'
import { authContext } from '@/common/context/auth_context'
import envVariables from '@/common/envVariables'
import { useError } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import type {
  AssetConditionDisplay,
  AssetTypePropDataModel,
} from '@/common/types'
import { AssetCondition } from '@/common/types'
import { Logger } from '@/config'
import { AssetsService, DeviceService } from '@/core'
import { CoreEventService, SubjectType } from '@/core/core-events'
import { getUniqueDeviceName } from '@/core/devices/device-utils'
import { getDeviceLocation } from '@/core/location'

import type { FieldType } from '..'
import {
  LibraryAssetCard,
  PageActionButtons,
  SellPage,
  TemplateField,
} from '..'
import type { MultipleValuesInputItem } from '../components/MultipleValuesInput'
import { MultipleValuesInput } from '../components/MultipleValuesInput'
import { useProductListing, useProductListingDispatch } from '../context'
import { useServiceListing } from '../context/service-listing-context'
import { ListingType } from '../types/listing-type'

const CONDITIONS: AssetConditionDisplay[] = [
  {
    condition: AssetCondition.Excellent,
    label: i18nKeys.sell.nav.details.asset.condition.excellent,
  },
  {
    condition: AssetCondition.VeryGood,
    label: i18nKeys.sell.nav.details.asset.condition.veryGood,
  },
  {
    condition: AssetCondition.Acceptable,
    label: i18nKeys.sell.nav.details.asset.condition.acceptable,
  },
  {
    condition: AssetCondition.Bad,
    label: i18nKeys.sell.nav.details.asset.condition.bad,
  },
  {
    condition: AssetCondition.Repair,
    label: i18nKeys.sell.nav.details.asset.condition.repair,
  },
]

const DEVICE_IS_PERSONAL_DEFAULT_VALUE = true

// TODO show errors to the user?
export function SellDetailsPage() {
  const { type } = useParams<{ type: string }>()

  const title = useServiceListing((s) => s.postingTitle)
  const description = useServiceListing((s) => s.description)
  const additionalInfo = useServiceListing((s) => s.additionalInfo)
  const termsOfService = useServiceListing((s) => s.termsOfService)
  const update = useServiceListing((s) => s.update)

  const [isLoading, setIsLoading] = useState(false)
  const { selectedAsset, productDetails, assetType, categories } =
    useProductListing()
  const { productDetailsActions, setEvent } = useProductListingDispatch()
  const [fields, setFields] = useState<AssetTypePropDataModel[]>([])
  const [values, setValues] = useState<Record<string, string>>({})
  const { userId, profile } = useContext(authContext)
  const { errors, changeError } = useError({
    fields: t(i18nKeys.sell.nav.details.asset.error.fields),
    name: t(i18nKeys.sell.nav.details.asset.error.name),
  })

  const { handleChangeField } = productDetailsActions

  async function fetchFields() {
    const service = new AssetsService()

    const template = await service.getTemplate({
      step: StepEnum.DATA,
      typeId: assetType!.id,
    })
    setFields(template.fields)
    setValues(
      template.fields.reduce(
        (acc, field) => {
          acc[field.propName] = field.defaultValue ?? ''
          return acc
        },
        {} as Record<string, string>,
      ),
    )
  }

  const selectedCondition = useMemo(
    () =>
      productDetails.condition
        ? {
            id: productDetails.condition?.condition,
            text: productDetails.condition?.label,
          }
        : undefined,
    [productDetails.condition],
  )

  const conditionOptions = useMemo(
    () =>
      CONDITIONS.map(
        (c) => ({ id: c.condition, text: t(c.label) }) as ComboboxOption,
      ),
    [t],
  )

  const handleEditCondition = useCallback(
    (option: ComboboxOption) => {
      handleChangeField('condition', {
        condition: option.id,
        label: option.text,
      } as AssetConditionDisplay)
    },
    [handleChangeField],
  )

  const handleChangeTemplateField = useCallback(
    (fieldName: string, value: string) => {
      setValues((prev) => ({ ...prev, [fieldName]: value }))
    },
    [setValues],
  )

  const handleContinue = useCallback(async () => {
    try {
      if (isLoading) {
        return false
      }

      if (type === ListingType.Asset) {
        if (values?.Name?.length < 3) {
          changeError('name', true)
          return false
        } else {
          changeError('name', false)
        }

        setIsLoading(true)

        // check all values, if any is empty, return false
        if (
          Object.values(values).some((v) => !v || v.trim() === '') ||
          !selectedCondition
        ) {
          changeError('fields', true)
          setIsLoading(false)
          return false
        }
      }
      const coreEventService = new CoreEventService()

      const devices = await DeviceService.searchDevices({
        isPersonal: DEVICE_IS_PERSONAL_DEFAULT_VALUE,
        name: getUniqueDeviceName(),
        userId: profile?.userId!,
      })

      if (devices.items.length === 0) {
        // TODO: show error
        setIsLoading(false)
        return false
      }

      const currentDeviceLocation = await getDeviceLocation()
      const ipAddress = await DeviceService.getIpAddress()

      let response: any = null

      if (type === ListingType.Asset) {
        response = await coreEventService.chainAssets({
          application: { id: envVariables.APP_NAME },
          data: {
            date: new Date().toISOString(),
            asset: {
              assetCategory: [...categories.map((c) => c.name)],
              assetName: values[Identifiers.ASSET_NAME_PROP],
              assetProperties: [
                ...Object.entries(values).map(([key, value]) => ({
                  id: key,
                  name: key,
                  value: value,
                })),
                {
                  id: Identifiers.ASSET_CONDITION_PROP,
                  name: Identifiers.ASSET_CONDITION_PROP,
                  value: selectedCondition!.text,
                },
              ],
              metadata: {
                assetTypeId: assetType!.id,
              },
              tags: productDetails.labels.map((l) => l.value),
            },
          },
          device: {
            date: new Date().toISOString(),
            deviceId: devices.items[0].id,
            geolocation: {
              latitude: currentDeviceLocation.coords.latitude,
              longitude: currentDeviceLocation.coords.longitude,
              meanSeaLevel: currentDeviceLocation.coords.altitude ?? 0,
            },
            ipAddress: ipAddress,
          } as DeviceData,
          subject: {
            subjectType: 'ASSET_OWNER',
            references: [
              {
                type: ReferenceType.END_USER_ID,
                value: userId!,
              },
            ],
          },
        })
      } else {
        response = await coreEventService.service.chainService({
          application: { id: envVariables.APP_NAME },
          data: {
            date: new Date().toISOString(),
            service: {
              serviceCategory: [...categories.map((c) => c.name)],
              serviceName: title,
              serviceProperties: [
                {
                  id: 'description',
                  name: 'description',
                  value: description,
                },
                {
                  id: 'additionalInfo',
                  name: 'additionalInfo',
                  value: additionalInfo,
                },
                {
                  id: 'termsOfService',
                  name: 'termsOfService',
                  value: termsOfService,
                },
              ],
              isFavorite: false,
              serviceType: assetType?.name!,
              metadata: {
                serviceTypeId: assetType!.id,
              },
            },
          },
          device: {
            date: new Date().toISOString(),
            deviceId: devices.items[0].id,
            geolocation: {
              latitude: currentDeviceLocation.coords.latitude,
              longitude: currentDeviceLocation.coords.longitude,
              meanSeaLevel: currentDeviceLocation.coords.altitude ?? 0,
            },
            ipAddress: ipAddress,
          },
          subject: {
            subjectType: SubjectType.ASSET_OWNER,
            references: [
              {
                type: ReferenceType.END_USER_ID,
                value: userId!,
              },
            ],
          },
        })
      }

      if (!response) {
        setIsLoading(false)
        return false
      }

      setEvent(response)
      setIsLoading(false)

      return true
    } catch (e) {
      Logger.error('Failed to chain assets', undefined, e as Error)
      setIsLoading(false)
      return false
    }
  }, [
    values,
    categories,
    assetType,
    selectedCondition,
    userId,
    isLoading,
    type,
    changeError,
    setEvent,
    setIsLoading,
  ])

  const handleChangeLabels = useCallback(
    (labels: MultipleValuesInputItem[]) => {
      handleChangeField('labels', labels)
    },
    [handleChangeField],
  )

  useEffect(() => {
    fetchFields()
  }, [])

  useEffect(() => {
    changeError('fields', false)
  }, [values, selectedCondition])

  if (type === ListingType.Asset) {
    return (
      <>
        {!!selectedAsset && <LibraryAssetCard asset={selectedAsset} />}

        <SellPage>
          <div className="w-full max-w-96 flex flex-col gap-4">
            {fields.map((field) => {
              return (
                <TemplateField
                  key={field.propName}
                  label={field.label}
                  options={
                    field.options
                      ?.filter((o) => o.visible)
                      .map((o) => ({ id: o.optionId, text: o.name })) ?? []
                  }
                  onChange={(value) =>
                    handleChangeTemplateField(field.propName, value)
                  }
                  value={values[field.propName]}
                  type={field.propType.name as FieldType}
                  name={field.propName}
                  className="w-full"
                  placeholder={field.hint ?? ''}
                  disabled={selectedAsset !== undefined}
                  maxLength={field.maxLength ?? 100}
                />
              )
            })}

            <Combobox
              label={t(i18nKeys.sell.nav.details.asset.condition.label)}
              options={conditionOptions}
              selected={selectedCondition}
              onChange={handleEditCondition}
            />

            <MultipleValuesInput
              items={productDetails.labels}
              onChange={handleChangeLabels}
              label={t(i18nKeys.sell.nav.details.asset.labelValue)}
              placeholder={t(
                i18nKeys.sell.nav.details.asset.labelValuePlaceholder,
              )}
              className="w-full"
            />

            <ErrorLabel error={errors.fields?.message} />
            <ErrorLabel error={errors.name?.message} />
          </div>
          <PageActionButtons
            onContinue={handleContinue}
            continueDisabled={isLoading}
          />
        </SellPage>
      </>
    )
  }

  return (
    <SellPage>
      <div className="w-full max-w-96 flex flex-col gap-4">
        <Input
          id="postingTitle"
          name="postingTitle"
          label="Posting Title"
          placeholder="Name the service you are offering"
          onChange={(v) => update('postingTitle', v.target.value)}
          value={title}
          className=""
          maxLength={100}
        />
        <Input
          id="description"
          name="description"
          label="Description"
          placeholder="Describe the service you are offering"
          onChange={(v) => update('description', v.target.value)}
          value={description}
          className=""
          maxLength={255}
        />
        <Input
          id="additionalInfo"
          name="additionalInfo"
          label="Additional Info"
          placeholder="Enter additional information about the service"
          onChange={(v) => update('additionalInfo', v.target.value)}
          value={additionalInfo}
          className=""
          maxLength={255}
        />

        <p className="text-center">
          Include any terms or conditions that apply to the service (e.g.,
          payment terms, cancellation policy).
        </p>

        <Input
          id="termsOfService"
          name="termsOfService"
          label="Terms of service"
          placeholder="Terms of service"
          onChange={(v) => update('termsOfService', v.target.value)}
          value={termsOfService}
          maxLength={255}
        />
      </div>
      <PageActionButtons
        onContinue={handleContinue}
        continueDisabled={isLoading}
      />
    </SellPage>
  )
}
