import { t } from 'i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useError } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'

import type { CardOption } from '..'
import {
  EmptyCard,
  OptionCards,
  PageActionButtons,
  PayoutAccountCard,
  SelectAssetSlideOver,
  SellPage,
} from '..'
import { PAYMENT_METHODS } from '../constants'
import { useProductListing, useProductListingDispatch } from '../context'
import { type PayoutAccount, PayoutCurrencyType } from '../types'

export function SellPaymentPage() {
  const [accountModalOpen, setAccountModalOpen] = useState(false)
  const { errors, changeError } = useError({
    chooseAccount: t(i18nKeys.sell.nav.payment.error.account),
  })

  const {
    cryptoAccounts,
    bankAccounts,
    payoutCurrency: payout,
    selectedAccount: account,
  } = useProductListing()
  const { setPayoutCurrency, setSelectedAccount } = useProductListingDispatch()

  const paymentMethods = useMemo(
    () =>
      PAYMENT_METHODS.map(
        (m) =>
          ({
            id: m.id,
            description: t(m.description),
            title: t(m.name),
            action: t(m.action),
            disabled: m.id === PayoutCurrencyType.Cryptocurrency,
          }) as CardOption,
      ),
    [],
  )

  const selectedOption = useMemo(
    () => paymentMethods.find((m) => m.id === payout),
    [payout, paymentMethods],
  )

  const handleChangePayout = useCallback(
    (option: CardOption) => {
      setPayoutCurrency(option.id)
    },
    [setPayoutCurrency],
  )

  const currencyIcon = useMemo(
    () => (payout === PayoutCurrencyType.USDollars ? 'bank' : 'bitcoin'),
    [payout],
  )

  const emptyAccountText = useMemo(
    () =>
      payout === PayoutCurrencyType.USDollars
        ? t(i18nKeys.sell.nav.payment.usd.empty)
        : t(i18nKeys.sell.nav.payment.crypto.empty),
    [payout],
  )

  const handleSelectAccount = useCallback(
    (account?: PayoutAccount) => {
      setAccountModalOpen(false)

      if (!account) {
        return
      }

      setSelectedAccount(account)
    },
    [setSelectedAccount, setAccountModalOpen],
  )

  const handleContinue = () => {
    if (!account) {
      changeError('chooseAccount', true)
      return false
    }

    return true
  }

  useEffect(() => {
    changeError('chooseAccount', false)
  }, [account])

  return (
    <>
      <SellPage title={t(i18nKeys.sell.nav.payment.title)}>
        <OptionCards
          options={paymentMethods}
          selected={selectedOption}
          onChange={handleChangePayout}
          className="flex flex-col sm:flex-row gap-2 sm:gap-8 w-full mt-8"
        />

        {account ? (
          <PayoutAccountCard
            account={account}
            defaultIcon={currencyIcon}
            className="w-full mt-9"
            mode="card"
            onClick={() => setAccountModalOpen(true)}
          />
        ) : (
          <EmptyCard
            icon={currencyIcon}
            text={emptyAccountText}
            onClick={() => setAccountModalOpen(true)}
            error={errors.chooseAccount.message}
          />
        )}

        <PageActionButtons onContinue={handleContinue} />
      </SellPage>

      <SelectAssetSlideOver
        items={
          payout === PayoutCurrencyType.USDollars
            ? bankAccounts
            : cryptoAccounts
        }
        RenderItem={({ index, item }) => (
          <PayoutAccountCard
            key={index}
            account={item}
            defaultIcon={currencyIcon}
            className="w-full"
            mode="button"
            onClick={() => handleSelectAccount(item)}
          />
        )}
        title={t(i18nKeys.sell.nav.payment.chooseModal.title)}
        onSearch={(search, item) =>
          item.entity.toLowerCase().includes(search) ||
          item.owner.toLowerCase().includes(search)
        }
        emptyMessage={t(i18nKeys.sell.nav.payment.chooseModal.empty)}
        emptySearch={(search) =>
          t(i18nKeys.sell.nav.payment.chooseModal.emptySearch).replace(
            '{SEARCH}',
            search,
          )
        }
        open={accountModalOpen}
        searchPlaceholder={t(
          i18nKeys.sell.nav.payment.chooseModal.searchPlaceholder,
        )}
        onClose={handleSelectAccount}
      />
    </>
  )
}
