import { t } from 'i18next'
import React from 'react'
import { useParams } from 'react-router-dom'

import i18nKeys from '@/common/i18nKeys'
import Spinner from '@/components/Spinner'

import { OrderDetail } from '../components/OrderDetail'
import { useOrderDetail } from '../hooks/use-order-detail'

export const OrderDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const { order, tracking, isLoading } = useOrderDetail({ orderId: id! })

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 sm:py-8 lg:px-8">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900">
          {t(i18nKeys.profile.order.detail.title)}
        </h1>
        {isLoading ? (
          <Spinner containerClassName="flex items-center justify-center py-12" />
        ) : (
          <OrderDetail order={order!} tracking={tracking!} />
        )}
      </div>
    </div>
  )
}
