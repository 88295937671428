import React from 'react'

import { SellPage, PageActionButtons, RadioListWithDescription } from '..'
import { servicePaymentTerms } from '../constants'
import { useServiceListing } from '../context/service-listing-context'

export default function ServicePaymentTermsPage() {
  const terms = useServiceListing((state) => state.paymentTerms)
  const update = useServiceListing((state) => state.update)

  return (
    <SellPage title="Payment terms">
      <RadioListWithDescription
        items={servicePaymentTerms}
        selected={terms}
        onChange={(v) => update('paymentTerms', v)}
        className="mt-6"
      />
      <PageActionButtons />
    </SellPage>
  )
}
