import { t } from 'i18next'
import React, { useCallback, useMemo } from 'react'

import i18nKeys from '@/common/i18nKeys'
import { ConditionalRendering } from '@/components'

import type { RadioOptionItem } from '..'
import {
  AlertWithActions,
  PageActionButtons,
  RadioListWithDescription,
  SellPage,
} from '..'
import { TRANSACTION_COMPLETENESS } from '../constants'
import { useProductListing, useProductListingDispatch } from '../context'

const VDT_NAME = 'John Doe' // todo: replace with backend data
const WALLET_NAME = 'Tether (USDT)' // todo: replace with backend data
const SHOW_REVENUE_DISCLAIMER = false // TODO To be implemented on a later milestone

export function SellTransactionPage() {
  const { completeness } = useProductListing()
  const { setCompletenessType: setCompleteness } = useProductListingDispatch()

  const completenessOptions = useMemo(
    () =>
      TRANSACTION_COMPLETENESS.map(
        (i) =>
          ({
            id: i.id,
            name: t(i.name),
            description: t(i.description),
          }) as RadioOptionItem,
      ),
    [t],
  )

  const selectedCompleteness = useMemo(
    () => completenessOptions.find((i) => i.id === completeness),
    [completenessOptions, completeness],
  )

  const handleChangeCompleteness = useCallback(
    (value: RadioOptionItem) => {
      setCompleteness(value.id)
    },
    [setCompleteness],
  )

  const revenueDescription = useMemo(
    () =>
      t(i18nKeys.sell.nav.transaction.revenue.text)
        .replace('{VDT}', VDT_NAME)
        .replace('{WALLET}', WALLET_NAME),
    [t],
  )

  return (
    <SellPage title={t(i18nKeys.sell.nav.transaction.title)}>
      <RadioListWithDescription
        items={completenessOptions}
        onChange={handleChangeCompleteness}
        selected={selectedCompleteness}
        className="mt-5 w-full"
      />

      <ConditionalRendering renderIf={SHOW_REVENUE_DISCLAIMER}>
        <AlertWithActions
          title={t(i18nKeys.sell.nav.transaction.revenue.title)}
          text={revenueDescription}
          actions={[
            {
              text: t(i18nKeys.sell.nav.transaction.revenue.modify),
            },
          ]}
          className="mt-5"
        />
      </ConditionalRendering>
      <PageActionButtons />
    </SellPage>
  )
}
