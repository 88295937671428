import type { PagedResponse } from '@bit-ui-libs/common'

import type { Order, OrderStatus, OrderTracking } from '@/common/types'

import { doGet, mapQueryParams } from '..'

const ORDERS_PER_PAGE = 10

export type GetOrdersParams = {
  orderType?: 'SELL' | 'BUY'
  assetId?: string
  orderState?: 'ON_GOING' | 'CANCELED' | 'COMPLETED'
  status?: OrderStatus
  page?: number
}

export class OrderService {
  static async getOrders(
    params: GetOrdersParams = {},
  ): Promise<PagedResponse<Order>> {
    const { page = 1, ...rest } = params
    return doGet(
      mapQueryParams('/marketplace/v1/orders', {
        ...rest,
        $page: page,
        $perPage: ORDERS_PER_PAGE,
      }),
    ) as Promise<PagedResponse<Order>>
  }
  static async getOrderById(orderId: string) {
    return doGet(
      mapQueryParams(`/marketplace/v1/orders/${orderId}`),
    ) as Promise<Order>
  }

  static async getTrackingById(trackingId: string): Promise<OrderTracking> {
    return doGet(`/marketplace/v1/orders/trackings/${trackingId}`)
  }
}
