import React, { useEffect, useState } from 'react'

import { useNotify } from '@/common/hooks'
import type { BuyerShippingAddress } from '@/common/types'
import type { ShippingCostResponseInterface } from '@/core'
import { ListingService } from '@/core'

import { SelectCarrier } from './SelectCarrier'
import { stepperProcess } from './Stepper'

export interface CarrierSelectionInterface {
  shippingAddress?: BuyerShippingAddress
  setStep: (e: number) => void
  setSelectedCarrier: (e: ShippingCostResponseInterface) => void
  selectedCarrier?: ShippingCostResponseInterface
  listingId: string
}

export const CarrierSelection: React.FC<CarrierSelectionInterface> = ({
  shippingAddress,
  setStep,
  setSelectedCarrier,
  selectedCarrier,
  listingId,
}) => {
  const [carriersList, setCarriersList] = useState<
    ShippingCostResponseInterface[]
  >([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { error } = useNotify()

  useEffect(() => {
    getInfo()
  }, [shippingAddress, listingId])

  const getInfo = async () => {
    try {
      // const carriers = await ListingService.getCarriers()
      setIsLoading(true)
      const carriers = await ListingService.calculateShippingCost({
        listingId: listingId,
        city: shippingAddress?.city,
        state: shippingAddress?.state,
        zip: shippingAddress?.zip,
        country: shippingAddress?.country,
      })

      // const paymentDetails = await ListingService.getPaymentDetails(listingId)
      //TODO: do i need to remove this call?

      setCarriersList(carriers)
      setIsLoading(false)
    } catch {
      error('Error')
      //TODO: add error msg
    }
  }

  return (
    <div className="min-h-[80vh]">
      <div className="bg-white p-8 max-w-4xl mx-auto sm:min-h-[512px] rounded-md flex flex-col">
        <SelectCarrier
          carriers={carriersList}
          setSelectedCarrier={setSelectedCarrier}
          selectedCarrier={selectedCarrier}
          onContinue={() => setStep(stepperProcess.selectPayment)}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}
