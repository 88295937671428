import i18nKeys from '../i18nKeys'
import type { BeingIdLevel } from '../types'

export const BEING_ID_LEVELS: BeingIdLevel[] = [
  {
    level: 1,
    name: i18nKeys.beingId.base10.level1.name,
    description: i18nKeys.beingId.base10.level1.description,
  },
  {
    level: 2,
    name: i18nKeys.beingId.base10.level2.name,
    description: i18nKeys.beingId.base10.level2.description,
  },
  {
    level: 3,
    name: i18nKeys.beingId.base10.level3.name,
    description: i18nKeys.beingId.base10.level3.description,
  },
  {
    level: 4,
    name: i18nKeys.beingId.base10.level4.name,
    description: i18nKeys.beingId.base10.level4.description,
  },
  {
    level: 5,
    name: i18nKeys.beingId.base10.level5.name,
    description: i18nKeys.beingId.base10.level5.description,
  },
  {
    level: 6,
    name: i18nKeys.beingId.base10.level6.name,
    description: i18nKeys.beingId.base10.level6.description,
  },
  {
    level: 7,
    name: i18nKeys.beingId.base10.level7.name,
    description: i18nKeys.beingId.base10.level7.description,
  },
  {
    level: 8,
    name: i18nKeys.beingId.base10.level8.name,
    description: i18nKeys.beingId.base10.level8.description,
  },
  {
    level: 9,
    name: i18nKeys.beingId.base10.level9.name,
    description: i18nKeys.beingId.base10.level9.description,
  },
  {
    level: 10,
    name: i18nKeys.beingId.base10.level10.name,
    description: i18nKeys.beingId.base10.level10.description,
  },
]

export const BEING_ID_LEVELS_6_BASED: BeingIdLevel[] = [
  {
    level: 1,
    name: i18nKeys.beingId.base6.level1.name,
    description: i18nKeys.beingId.base6.level1.description,
  },
  {
    level: 2,
    name: i18nKeys.beingId.base6.level2.name,
    description: i18nKeys.beingId.base6.level2.description,
  },
  {
    level: 3,
    name: i18nKeys.beingId.base6.level3.name,
    description: i18nKeys.beingId.base6.level3.description,
  },
  {
    level: 4,
    name: i18nKeys.beingId.base6.level4.name,
    description: i18nKeys.beingId.base6.level4.description,
  },
  {
    level: 5,
    name: i18nKeys.beingId.base6.level5.name,
    description: i18nKeys.beingId.base6.level5.description,
  },
  {
    level: 6,
    name: i18nKeys.beingId.base6.level6.name,
    description: i18nKeys.beingId.base6.level6.description,
  },
]
