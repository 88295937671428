import type { Bid, PagedResponse } from '@bit-ui-libs/common'
import { t } from 'i18next'
import React, { useContext, useEffect, useState } from 'react'

import { authContext } from '@/common/context/auth_context'
import { useNotify } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import Spinner from '@/components/Spinner'
import { Logger } from '@/config'
import type { CustomerAssetWithPrice, ListAssetParams } from '@/core'
import { ListingService } from '@/core'

import { BidsList } from '../components/bidTracking/BidsList'

const tabs = [
  { name: 'My Bids', value: 0 },
  { name: 'My Auctions', value: 1 },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// TODO missing translations
export const AuctionHistoryPage: React.FC = () => {
  const { profile } = useContext(authContext)
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [bids, setBids] =
    useState<PagedResponse<Bid | CustomerAssetWithPrice>>()
  const { error } = useNotify()

  useEffect(() => {
    if (selectedTab === 0) {
      handleFetchHistory()
    } else {
      handleFetchMycollection()
    }
  }, [profile, selectedTab])

  const handleFetchMycollection = async (page: number = 1) => {
    if (!profile?.userId) {
      return
    }

    try {
      const options = {
        ownerId: profile.userId,
        sellingMethod: 'auction',
        $page: page,
      } as ListAssetParams

      const bids = await ListingService.getListingsList(options)

      setBids(bids)
    } catch (err) {
      // TODO check if the message is correct
      Logger.error('Failed to fetch my collection', undefined, err as Error)
      error('Failed to fetch my collection')
    }
  }

  const handleFetchHistory = async (page: number = 1) => {
    if (!profile?.userId) {
      return
    }

    try {
      const bids = await ListingService.getAllbidsFromUser(profile.userId, page)
      setBids(bids)
    } catch (err) {
      // TODO check if the message is correct
      Logger.error('Failed to fetch bid history', undefined, err as Error)
      error('Failed to fetch bid history')
    }
  }

  const handleSelectTab = (selected: number) => {
    setBids(undefined)
    setSelectedTab(selected)
  }

  return (
    <section
      id="auctionList"
      className="mx-auto max-w-7xl sm:px-2 lg:px-8 bg-white py-6 sm:py-8 "
    >
      <div className="mx-auto max-w-7xl sm:px-2 lg:px-8">
        <div className="mx-auto max-w-2xl px-4 lg:max-w-4xl lg:px-0">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
            {t(i18nKeys.bid.title)}
          </h1>
          <p className="mt-2 text-sm text-gray-500">
            {t(i18nKeys.bid.description)}
          </p>
        </div>
      </div>

      <div className="mt-16">
        <div className="mx-auto max-w-7xl sm:px-2 lg:px-8">
          <div className="border-b border-gray-200 mb-3">
            <nav className="-mb-px flex justify-center" aria-label="Tabs">
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  onClick={() => handleSelectTab(tab.value)}
                  className={classNames(
                    tab.value === selectedTab
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'w-1/4 border-b-2 px-1 py-4 text-center text-sm font-medium',
                  )}
                  aria-current={tab.value === selectedTab ? 'page' : undefined}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>

          <div className="mx-auto max-w-2xl space-y-8 sm:px-4 lg:max-w-4xl lg:px-0">
            {bids ? (
              <BidsList
                {...bids}
                onSearch={async (page: number) => handleFetchHistory(page + 1)}
                selectedTab={selectedTab}
              />
            ) : (
              <Spinner containerClassName="flex items-center justify-center py-12" />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
