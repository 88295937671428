export type LibraryAsset = {
  id: string
  imageSrc: string
  name: string
  description: string
  category: string
  subcategory: string
  isSelfVerified: boolean
  isVDTMinted: boolean
  grade: string
  zipCode: number
  labels: AssetLabel[]
  assetTypeId: string
  images: AssetImage[]
}

export type AssetLabel = {
  key: string
  value: string
}

export enum AssetCondition {
  Excellent = 'Excellent',
  VeryGood = 'VeryGood',
  Acceptable = 'Acceptable',
  Bad = 'Bad',
  Repair = 'Repair',
}

export type AssetConditionDisplay = {
  condition: AssetCondition
  label: string
}

export type AssetImage = {
  id?: string
  src: string
  alt?: string
  status: 'idle' | 'invalid' | 'loading' | 'success' | 'error'
  isMain?: boolean
  errors?: { code: string; detail: string }[]
  file?: File
}

export type AssetTypePropDataModel = {
  propName: string
  propTypeId: number
  propType: {
    id: number
    name: string
    friendlyName: string
    type: string
    hasOption: boolean
    multiValue: boolean
  }
  label: string
  required: boolean
  order: number
  visible: boolean
  hint: string | null
  defaultValue: string | null
  maxLength: number | null
  options:
    | {
        optionId: string
        name: string
        visible: boolean
        order: number
      }[]
    | null
}

export type AssetTemplate = {
  id: string
  typeId: string | null
  typeName?: string | null
  step: string | null
  name: string
  title: string
  fields: AssetTypePropDataModel[]
  description: string | null
  status: string
  createdAt: string | null
  updatedAt: string | null
  activatedAt: string | null
  archivedAt: string | null
  hint: string
}
