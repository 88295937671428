import React, { useMemo } from 'react'
import { FaMapMarker } from 'react-icons/fa'

import { TaxCodeAutocomplete } from '@/common/components/form/autocomplete/TaxCodeAutocomplete'
import { useNotify } from '@/common/hooks'

import { InfoAlert, OptionCards, PageActionButtons, SellPage } from '..'
import { TaxDisclaimerModal } from '../components/TaxDisclaimerModal'
import { taxCategories } from '../constants/taxes'
import { useProductListing } from '../context'
import { useListingStore } from '../context/listing-context'
import { TaxCategoryType } from '../types/taxes'

export default function SellTaxPage() {
  const { error } = useNotify()
  const { delivery } = useProductListing()
  const taxCategoryType = useListingStore((state) => state.taxCategoryType)
  const taxCode = useListingStore((state) => state.taxCode)
  const update = useListingStore((state) => state.update)

  const canContinue = useMemo(() => {
    if (taxCategoryType.id === TaxCategoryType.GeneralTaxes) {
      return true
    }

    return Boolean(taxCode)
  }, [taxCategoryType, taxCode])

  const handleContinue = () => {
    if (taxCategoryType.id === TaxCategoryType.SpecialTaxCategory && !taxCode) {
      error('Please select a tax code')
      return false
    }
    return true
  }

  return (
    <SellPage title="Select your tax category">
      <InfoAlert
        title="Disclaimer"
        text="Black Ink Technology assumes no responsibility for the accuracy of the item category selected by the seller. It is the seller's sole responsibility to ensure that the correct category is chosen for the items listed for sale."
        linkText="Details"
        href="?disclaimer=true"
        className="mt-4"
      />
      <TaxDisclaimerModal />

      <OptionCards
        className="mt-4 flex gap-2"
        selected={taxCategoryType}
        options={taxCategories}
        onChange={(i) => update('taxCategoryType', i)}
      />

      {taxCategoryType.id === TaxCategoryType.GeneralTaxes ? (
        <div className="fle flex-col gap-1 mt-4">
          <span className="text-sm flex items-center gap-2">
            <FaMapMarker className="fill-primary-400 size-4" />
            {delivery.address?.city}, {delivery.address?.state}
          </span>

          <p className="font-light text-xs text-gray-400">
            By continuing, we will apply the general tax rate based on the
            location in your profile. Please ensure that your profile location
            is up to date for accurate tax calculation.
          </p>
        </div>
      ) : null}

      {taxCategoryType.id === TaxCategoryType.SpecialTaxCategory ? (
        <TaxCodeAutocomplete
          label="Tax codes"
          selected={taxCode ?? undefined}
          onChange={(v) => update('taxCode', v)}
          className="w-full mt-6"
        />
      ) : null}

      {taxCategoryType.id === TaxCategoryType.SpecialTaxCategory && taxCode ? (
        <div className="flex flex-col w-full mt-4 gap-1">
          {/* <span className="text-sm">#{taxCode.taxCode}</span> */}
          <p className="font-light text-xs text-gray-400">
            {taxCode.description}
          </p>
        </div>
      ) : null}

      <PageActionButtons
        onContinue={handleContinue}
        continueDisabled={!canContinue}
      />
    </SellPage>
  )
}
