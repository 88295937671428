import { t } from 'i18next'
import React, { useMemo } from 'react'

import i18nKeys from '@/common/i18nKeys'
import { featureFlags } from '@/config'
import { classNames, type PaymentDetailsResponse } from '@/core'

export interface TotalCardPropsInterface {
  costs?: PaymentDetailsResponse
}

type DisplayCost = {
  label: string
  price: string
  hidden?: boolean
  highlight?: boolean
}

export const TotalCard: React.FC<TotalCardPropsInterface> = ({ costs }) => {
  const displayCosts = useMemo(() => {
    return (
      [
        {
          label: t(i18nKeys.profile.order.detail.costs.products),
          price: costs?.sellingPrice ? `$${costs?.sellingPrice}` : '-',
        },
        {
          label: t(i18nKeys.profile.order.detail.costs.shipping),
          price: costs?.shippingCost ? `$${costs?.shippingCost}` : '-',
        },
        {
          label: t(i18nKeys.profile.order.detail.costs.gas),
          price: costs?.gasFee ? `$${costs?.gasFee}` : '-',
          hidden: !featureFlags.gasFee,
        },
        {
          label: t(i18nKeys.profile.order.detail.costs.tax),
          price: costs?.costTax ? `$${costs?.costTax}` : '-',
        },
        {
          label: t(i18nKeys.profile.order.detail.costs.total),
          price: costs?.amount ? `$${costs?.amount}` : '-',
          highlight: true,
        },
      ] as DisplayCost[]
    ).filter((i) => !i.hidden)
  }, [costs])

  return (
    <div className="mx-auto p-5 bg-white container max-w-screen-lg">
      <div className="font-bold text-2xl">
        {t(i18nKeys.buyerProcess.summary.title)}
      </div>

      <div className="flex flex-col gap-5 mt-6">
        {displayCosts.map((cost, index) => (
          <div
            key={index}
            className={classNames(
              'flex justify-between',
              cost.highlight && 'mt-8 text-20 font-bold text-xl',
            )}
          >
            <p>{cost.label}</p>
            <p className="font-bold">{cost.price}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
