import React from 'react'

import type { PaymentCard } from '@/core'
import { classNames, formatExpirationDate } from '@/core'

import { PaymentCardIcon } from './PaymentCardIcon'

export type PaymentCardProps = {
  card: PaymentCard
  className?: string
  rightComponent?: React.ReactNode
  onClick?: () => void
}

export const PaymentCardInfo: React.FC<PaymentCardProps> = (props) => {
  return (
    <button
      className={classNames(
        'bg-white shadow-md p-4 rounded-md mb-5',
        props.className,
      )}
      onClick={props.onClick}
    >
      <div className="flex justify-between items-center">
        <div className="flex gap-4">
          <PaymentCardIcon type={props.card.type} className="w-12" />
          <div>
            <div className="text-sm font-semibold">
              **** {props.card.lastDigits}
            </div>
            <div className="text-xs text-gray-500">
              {/* TODO add translation */}
              Expires{' '}
              {formatExpirationDate(
                props.card.cardExpiry.month,
                props.card.cardExpiry.year,
              )}
            </div>
          </div>
        </div>
        {props.rightComponent ? props.rightComponent : null}
      </div>
    </button>
  )
}
