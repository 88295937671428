import type { AddressTypeEnum } from '@bit-ui-libs/common'
import { SellingMethodEnum } from '@bit-ui-libs/common'
import { t } from 'i18next'
import { isUndefined } from 'lodash'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Icon } from '@/common/components'
import { ProductPreview } from '@/common/components/productPreview'
import { authContext } from '@/common/context/auth_context'
import { useForm, useNotify } from '@/common/hooks'
import i18nKeys from '@/common/i18nKeys'
import { ConditionalRendering } from '@/components'
import { Logger } from '@/config'
import { AssetsService, ETransactionCompleteness, UserService } from '@/core'
import { colors } from '@/theme'
import MarketplaceIcon from '@assets/images/chainIT_marketplace_icon.png'

import { DescriptionItem, InfoAlert, PreviewCard } from '..'
import {
  PRIVACY_OPTIONS,
  SELL_METHODS,
  TRANSACTION_COMPLETENESS,
} from '../constants'
import { useProductListing } from '../context'
import { useListingStore } from '../context/listing-context'
import type { AssetLabel, LibraryAsset, SellMethodForm } from '../types'
import {
  AuctionType,
  SellingMethodType,
  TransactionCompletenessType,
} from '../types'
import { TaxCategoryType } from '../types/taxes'

function mapSellingMethod(method: SellMethodForm) {
  if (method.method === SellingMethodType.FixedPrice) {
    return SellingMethodEnum.Fixed
  }

  switch (method.auctionType) {
    case AuctionType.Timed:
      return SellingMethodEnum.Auction
    case AuctionType.Dutch:
      return SellingMethodEnum.Dutch
    case AuctionType.Inverse:
      return SellingMethodEnum.Inverse
  }
}

const completenessMapping: Record<
  TransactionCompletenessType,
  ETransactionCompleteness
> = {
  [TransactionCompletenessType.TimeOfSale]:
    ETransactionCompleteness.OnTimeOfSale,
  [TransactionCompletenessType.ItemDelivered]:
    ETransactionCompleteness.OnItemDelivered,
  [TransactionCompletenessType.DeliveredAndInspected]:
    ETransactionCompleteness.OnDeliveredAndInspected,
}

export function SellPreviewPage() {
  const nav = useNavigate()
  const { sellerProfile, userId, profile } = useContext(authContext)
  const { error: notifyError, fromError, success } = useNotify()
  const { event } = useProductListing()
  const {
    selectedAsset,
    selectedAccount,
    images,
    sellMethod: selectedMethod,
    completeness,
    delivery,
    privacy,
  } = useProductListing()
  const taxCategoryType = useListingStore((state) => state.taxCategoryType)
  const taxCode = useListingStore((state) => state.taxCode)

  const { data } = useForm({
    product: selectedAsset,
    images: (selectedAsset ? selectedAsset.images : images) ?? [],
    price: selectedMethod.price,
    payout: selectedAccount,
    method: selectedMethod,
    transactionComplete: completeness,
    shipping: {
      from: delivery.address?.address,
      width: delivery.width,
      height: delivery.height,
      length: delivery.length,
      weight: delivery.weight,
    },
    privacy: {
      share: privacy,
      // buyerBeingId: buyerLevel,
    },
    units: {
      length: 'in',
      weight: 'lb',
    },
  })

  const [product, setProduct] = useState<LibraryAsset>()

  const fetchProduct = async () => {
    if (selectedAsset) {
      setProduct(selectedAsset)
      return
    }

    const service = new AssetsService()
    const asset = await service.getByEventId(event!.id)
    setProduct({
      id: asset.id,
      name: asset.name,
      labels: [] as AssetLabel[],
      description:
        asset.serializedProps.find((i) => i?.name === 'Description')?.value ??
        '', // TODO: description name
    } as LibraryAsset)
  }
  useEffect(() => {
    fetchProduct()
  }, [])

  const sellMethod = useMemo(
    () => t(SELL_METHODS.find((i) => i.id === data.method.method)!.name),
    [t, data.method],
  )

  const transactionComplete = useMemo(
    () =>
      t(
        TRANSACTION_COMPLETENESS.find((i) => i.id === data.transactionComplete)!
          .name,
      ),
    [t, data.transactionComplete],
  )

  const privacyShare = useMemo(
    () => t(PRIVACY_OPTIONS.find((i) => i.id === data.privacy.share)!.name),
    [t, data.privacy.share],
  )

  // const buyerBeingId = useMemo(() => {
  //   const level = BEING_ID_LEVELS_6_BASED.find(
  //     (i) => i.level === data.privacy.buyerBeingId,
  //   )!

  //   return `${t(i18nKeys.common.beingId)} ${t(i18nKeys.common.level)} ${level.level} · ${t(level.name)}`
  // }, [t, data.privacy.buyerBeingId])

  const handleSubmit = async () => {
    try {
      if (!product) {
        // TODO show error
        return
      }

      if (!sellerProfile) {
        notifyError('Seller profile not created!')
        return
      }

      const userService = new UserService()
      const addresses = await userService.listSellerAddresses(
        sellerProfile?.id!,
        {
          userId: userId!,
          id: delivery.address?.id,
        },
      )

      if (
        addresses.length === 0 ||
        !addresses.find((i) => i.id === delivery.address?.id)
      ) {
        Logger.info('Linking address to seller profile')

        await userService.createSellerAddress(sellerProfile?.id!, {
          addressId: delivery.address?.id!,
          profileId: sellerProfile?.id!,
          isDefault: delivery.address?.isDefault! ?? false,
          phone: profile?.phone ?? 'N/A',
          userId: userId!,
          type: delivery.address?.type! as unknown as AddressTypeEnum,
          name: delivery.address?.name!,
          address: delivery.address?.name!,
          addressLine1: delivery.address?.address!,
          addressLine2: delivery.address?.address!,
          city: delivery.address?.city!,
          state: delivery.address?.state!,
          zip: delivery.address?.zip!,
          country: delivery.address?.country!,
          isMain: delivery.address?.isMain!,
          shipInternationally: false,
        })

        Logger.info('Linked address to seller profile')
      }

      const userPayments = await userService.getPaymentDetails(userId!)
      const sellerPayments = userPayments.filter(
        (p) => p.profileId === sellerProfile.id,
      )

      if (!sellerPayments.find((i) => i.isDefault)) {
        try {
          // Try to set the first payment on the list as default
          if (sellerPayments.length > 0) {
            Logger.info('Setting first payment as default')
            const firstPayment = sellerPayments[0]

            await userService.patchSellerPaymentDetails(
              sellerProfile?.id!,
              firstPayment.id,
              {
                isDefault: true,
                paymentDetailId: firstPayment.id!,
                profileId: sellerProfile?.id!,
              },
            )
          } else {
            // Set the selected bank account as default seller payment
            Logger.info('Setting selected payment as default')

            const paymentDetail = await userService.getPaymentDetailById(
              sellerProfile?.id!,
              data.payout!.id,
            )

            if (!paymentDetail) {
              notifyError('Payment detail not found')
              throw new Error('Payment detail not found')
            }

            await userService.createSellerPaymentDetails(sellerProfile?.id!, {
              paymentDetailId: paymentDetail.id,
              profileId: sellerProfile?.id!,
              userId: userId!,
              type: paymentDetail.type,
              data: {
                bankName: paymentDetail.data.bankName!,
                accountNumber: paymentDetail.data.accountNumber!,
                routingNumber: paymentDetail.data.routingNumber!,
              },
              isDefault: true,
            })

            Logger.info('Payment set as default')
          }
        } catch (e) {
          Logger.error(
            'Failed to set payment as default',
            undefined,
            e as Error,
          )
          // TODO notify user
        }
      }

      const service = new AssetsService()
      await service.createListing({
        id: product.id,
        price: +data.price,
        taxCode:
          taxCategoryType.id === TaxCategoryType.SpecialTaxCategory
            ? taxCode?.taxCode
            : undefined,
        description: product.description,
        isFreeShipping: false, // for later milestone
        revenue: 2, // TODO - is this a percentage?
        sellingMethod: mapSellingMethod(selectedMethod),
        shippingDetail: {
          handlingFee: 1, // not used, but required to be at least 1
          weight: {
            value: +data.shipping!.weight,
            unit: 'lb',
          },
          dimension: {
            width: +data.shipping!.width,
            height: +data.shipping!.height,
            depth: +data.shipping!.length,
            unit: 'in',
          },
          shipFrom: {
            address: data.shipping!.from!,
            addressLine1: data.shipping!.from!,
            city: delivery.address?.city!,
            state: delivery.address?.state!,
            country: delivery.address?.country!,
            zip: delivery.address?.zip!,
            name: delivery.address?.name!,
            phone: profile?.phone ?? 'N/A',
            type: delivery.address?.type!,
          },
        },
        buyerBeingId: 6, //data.privacy.buyerBeingId,
        completeness: completenessMapping[data.transactionComplete],
        payoutAccountId: data.payout!.id,
        startAt: (selectedMethod.startTime?.length
          ? new Date(selectedMethod.startTime)
          : new Date()
        ).toISOString(),
        endAt:
          selectedMethod.method === SellingMethodType.Auction
            ? new Date(selectedMethod.endTime).toISOString()
            : undefined,
      })

      success('Listing created successfully!')
      nav(`/myLibrary/asset/${product.id}/detail`)
    } catch (e) {
      Logger.error('Failed to create listing', undefined, e as Error)
      fromError({
        error: e as Error,
        defaultMessage: 'Failed to create listing',
      })
    }
  }

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-row justify-between">
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
          {t(i18nKeys.sell.nav.preview.title)}
        </h1>
        <button
          type="submit"
          onClick={handleSubmit}
          className="hidden sm:flex items-center justify-center rounded-md border border-transparent bg-primary px-8 py-3 text-base font-medium text-white hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-50 h-fit"
        >
          {t(i18nKeys.ui.submit)}
        </button>
      </div>

      <InfoAlert
        title={t(i18nKeys.sell.nav.preview.info.title)}
        text={t(i18nKeys.sell.nav.preview.info.text)}
      />

      <ConditionalRendering renderIf={!isUndefined(product)}>
        <ProductPreview
          images={
            !data.images?.length
              ? [
                  {
                    src: MarketplaceIcon,
                    alt: product?.name,
                    status: 'success',
                  },
                ]
              : data.images
          }
          price={data.price}
          product={product!}
        />
      </ConditionalRendering>

      <PreviewCard title={t(i18nKeys.sell.nav.method.label)}>
        <DescriptionItem title={sellMethod} description={`$${data.price}`} />
        <DescriptionItem
          title={taxCategoryType.title}
          description={
            taxCategoryType.id === TaxCategoryType.SpecialTaxCategory
              ? taxCode?.name ?? ''
              : `${delivery.address?.city}, ${delivery.address?.state}`
          }
        />
      </PreviewCard>

      <PreviewCard title={t(i18nKeys.sell.nav.payment.label)}>
        <div className="flex flex-col gap-4 sm:gap-0 sm:flex-row">
          <p>{t(i18nKeys.sell.nav.preview.payoutOn)}</p>

          <div className="flex-1 sm:ms-6">
            <div className="flex rounded shadow p-2 max-w-xs w-full items-center">
              {data.payout?.imageSrc ? (
                <img
                  src={data.payout?.imageSrc}
                  className="size-10"
                  alt={data.payout?.entity}
                />
              ) : (
                <Icon icon="bank" size={32} color={colors.gray[500]} />
              )}
              <div className="flex flex-col ms-2">
                <h3 className="text-sm font-medium">{data.payout?.entity}</h3>
                <p className="text-sm">{data.payout?.owner}</p>
              </div>
            </div>
          </div>
          <p className="text-sm font-medium">{transactionComplete}</p>
        </div>
      </PreviewCard>

      <PreviewCard title={t(i18nKeys.sell.nav.delivery.shipping.label)}>
        <DescriptionItem
          title={t(i18nKeys.sell.nav.delivery.shipFrom)}
          description={data.shipping.from!}
        />
        <DescriptionItem
          title="Dimensions"
          description={`${data.shipping.width}${data.units.length} x ${data.shipping.height}${data.units.length} x ${data.shipping.length}${data.units.length}`}
        />
        <DescriptionItem
          title={t(i18nKeys.sell.nav.delivery.weight)}
          description={`${data.shipping.weight}${data.units.weight}`}
        />
      </PreviewCard>

      <PreviewCard title={t(i18nKeys.sell.nav.privacy.label)}>
        <DescriptionItem
          title={t(i18nKeys.sell.nav.preview.privacy.share)}
          description={privacyShare}
        />
        {/* <DescriptionItem
          title={t(i18nKeys.sell.nav.preview.privacy.buyerLevel)}
          description={buyerBeingId}
        /> */}
      </PreviewCard>

      <div className="flex flex-row justify-between mt-8">
        <p className="font-medium text-gray-900">
          {t(i18nKeys.sell.nav.preview.confirmText)}
        </p>
        <button
          type="submit"
          onClick={handleSubmit}
          className="flex items-center justify-center rounded-md border border-transparent bg-primary px-8 py-3 text-base font-medium text-white hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-50 h-fit"
        >
          {t(i18nKeys.ui.submit)}
        </button>
      </div>
    </div>
  )
}
