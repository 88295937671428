import type { CardOption } from '..'
import { ServiceAvailabilityOptionType } from '../types/availability'

export const serviceAvailabilityOptionTypes: CardOption[] = [
  {
    id: ServiceAvailabilityOptionType.Calendar,
    title: 'Day of the week',
    description: 'Specify your availability by day of the week',
  },
  {
    id: ServiceAvailabilityOptionType.ExternalLink,
    title: 'Calendly link',
    description: 'Add a Calendly link to let customers book time with you',
  },
]
