import { t } from 'i18next'
import React, { useMemo } from 'react'

import { PaymentCardIcon } from '@/common/components'
import i18nKeys from '@/common/i18nKeys'
import type { Order, OrderStatus, OrderTracking } from '@/common/types'
import Spinner from '@/components/Spinner'
import { featureFlags } from '@/config'
import type { PaymentCardType } from '@/core'
import { classNames, formatExpirationDate } from '@/core'
import { colors } from '@/theme'

import { useAsset } from '../hooks/use-asset'

import { statusMapping } from './OrderItem'

export type OrderDetailProps = {
  order: Order
  tracking: OrderTracking
}

// TODO duplicated code on TotalCard.tsx
type DisplayCost = {
  label: string
  price: string
  highlight?: boolean
  hidden?: boolean
}

const orderSteps = [
  i18nKeys.profile.order.detail.steps.orderPlaced,
  i18nKeys.profile.order.detail.steps.shipped,
  i18nKeys.profile.order.detail.steps.delivered,
  i18nKeys.profile.order.detail.steps.ownership,
]

type OrderStatusState = 'OK' | 'WARN' | 'ERROR'

const statusColorMapping: Record<OrderStatusState, string> = {
  OK: colors.primary[600],
  WARN: colors.yellow,
  ERROR: colors.error,
}

const orderStatusMapping: Record<
  OrderStatus,
  { progress: number; status: OrderStatusState; label?: string }
> = {
  CREATED: {
    progress: 0,
    status: 'OK',
  },
  PAYMENT_COMPLETED: {
    progress: 0,
    status: 'OK',
  },
  PAYMENT_FAILED: {
    progress: 0,
    status: 'ERROR',
  },
  CANCELLED: {
    progress: 0,
    status: 'ERROR',
  },
  ORDER_SHIPPED: {
    progress: 1,
    status: 'OK',
  },
  ORDER_DELIVERED: {
    progress: 2,
    status: 'OK',
  },
  OWNERSHIP_TRANSFER_STARTED: {
    progress: 2,
    status: 'OK',
  },
  OWNERSHIP_TRANSFER_COMPLETED: {
    progress: 5,
    status: 'OK',
  },
  OWNERSHIP_TRANSFER_FAILED: {
    progress: 2,
    status: 'ERROR',
  },
  ORDER_UNDELIVERED: {
    progress: 3,
    status: 'WARN',
  },
  ORDER_SUCCESS: {
    progress: 3,
    status: 'OK',
  },
  RETURN: {
    progress: 3,
    status: 'WARN',
  },
  RETURN_SHIPPED: {
    progress: 3,
    status: 'WARN',
  },
  RETURN_DELIVERED: {
    progress: 3,
    status: 'WARN',
  },
  RETURN_UNDELIVERED: {
    progress: 3,
    status: 'ERROR',
  },
  REFUND: {
    progress: 3,
    status: 'OK',
  },
  REFUND_COMPLETED: {
    progress: 3,
    status: 'WARN',
  },
  REFUND_FAILED: {
    progress: 3,
    status: 'ERROR',
  },
}

const shippingSlugMapping: Record<string, string> = {
  ups: 'https://www.ups.com/track?loc=en_US&tracknum={TRACKING}',
  fedex:
    'https://www.fedex.com/wtrk/track/?action=track&cntry_code=us&locale=en_us&trackingnumber={TRACKING}',
}

export const OrderDetail: React.FC<OrderDetailProps> = ({
  order,
  tracking,
}) => {
  const { asset, imageSrc, description } = useAsset({
    assetId: order.assetId,
  })

  const dateCreated = useMemo(() => {
    if (!order) return
    return new Date(order.createdAt).toLocaleDateString()
  }, [order?.createdAt])

  const shippingHref = useMemo(() => {
    const trackingNumber = order.shipment.trackingNumber
    const carrier = order.shipment.slug

    if (!trackingNumber || !carrier) return

    return shippingSlugMapping[carrier].replace('{TRACKING}', trackingNumber)
  }, [order])

  // TODO duplicated code on TotalCard.tsx
  const displayCosts = useMemo(() => {
    return (
      [
        {
          label: t(i18nKeys.profile.order.detail.costs.products),
          price: order.expenses?.price ? `$${order.expenses?.price}` : '-',
        },
        {
          label: t(i18nKeys.profile.order.detail.costs.shipping),
          price: order.expenses?.shippingCost
            ? `$${order.expenses?.shippingCost}`
            : '-',
        },
        {
          label: t(i18nKeys.profile.order.detail.costs.gas),
          price: order.expenses?.gasFee ? `$${order.expenses?.gasFee}` : '-',
          hidden: !featureFlags.gasFee,
        },
        {
          label: t(i18nKeys.profile.order.detail.costs.tax),
          price: order.expenses?.costTax ? `$${order.expenses?.costTax}` : '-',
        },
        {
          label: t(i18nKeys.profile.order.detail.costs.total),
          price: order.expenses?.amount ? `$${order.expenses?.amount}` : '-',
          highlight: true,
        },
      ] as DisplayCost[]
    ).filter((i) => !i.hidden)
  }, [order.expenses])

  const currentStep = useMemo(() => {
    const _step = orderStatusMapping[order.status!]
    return { ..._step, label: _step.label ?? orderSteps[_step.progress] }
  }, [order])

  const deliveryAddress = 'Main st 123, NY' // TODO backend is not returning the Shipping Address
  const billingAddressLines = [
    'Floyd Miles',
    '7363 Cynthia Pass',
    'Toronto, ON N3Y 4H8',
  ] // TODO backend is not returning the Billing Address

  const statusTimestamp = useMemo(
    () =>
      t(i18nKeys.profile.order.detail.statusTimestamp)
        .replace('{STATUS}', t(statusMapping[order.status!].label))
        .replace('{TIMESTAMP}', dateCreated!),
    [t, order, dateCreated, statusMapping],
  )

  const cardEnding = useMemo(
    () =>
      t(i18nKeys.profile.order.detail.cardEnding).replace(
        '{LAST_DIGITS}',
        order.transactionDetails.card.lastDigits,
      ),
    [t, order],
  )

  const cardExpiration = useMemo(
    () =>
      t(i18nKeys.profile.order.detail.cardExpires).replace(
        '{EXPIRATION}',
        formatExpirationDate(
          +order.transactionDetails.card.cardExpiry.month,
          +order.transactionDetails.card.cardExpiry.year,
        ),
      ),
    [t, order],
  )

  return (
    <>
      <div className="mt-2 border-b border-gray-200 pb-5 text-sm sm:flex sm:justify-between">
        <dl className="flex">
          <dt className="text-gray-500">
            {t(i18nKeys.profile.order.detail.orderNumber)}&nbsp;
          </dt>
          <dd className="font-medium text-gray-900">
            {order.shipment.trackingNumber}
          </dd>
          <dt>
            <span className="mx-2 text-gray-400" aria-hidden="true">
              &middot;
            </span>
          </dt>
          <dd className="font-medium text-gray-900">
            <time dateTime="2021-03-22">{dateCreated}</time>
          </dd>
        </dl>
        <div className="mt-4 sm:mt-0">
          <a
            href={shippingHref}
            className="font-medium text-primary-600 hover:text-primary-500"
          >
            <span>{t(i18nKeys.profile.order.detail.viewShipping)}</span>
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </div>

      <div className="mt-8">
        <div className="space-y-24">
          <div className="grid grid-cols-1 text-sm sm:grid-cols-12 sm:grid-rows-1 sm:gap-x-6 md:gap-x-8 lg:gap-x-8">
            <div className="sm:col-span-4 md:col-span-5 md:row-span-2 md:row-end-2">
              <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-50">
                {imageSrc ? (
                  <img
                    src={imageSrc}
                    alt={asset?.name}
                    className="h-full w-full object-cover object-center"
                  />
                ) : (
                  <div className="flex items-center justify-center h-full">
                    <Spinner />
                  </div>
                )}
              </div>
            </div>
            <div className="mt-6 sm:col-span-7 sm:mt-0 md:row-end-1">
              <h3 className="text-lg font-medium text-gray-900">
                <a href={`/myLibrary/asset/${order.assetId}/detail`}>
                  {asset?.name}
                </a>
              </h3>
              <p className="mt-1 font-medium text-gray-900">${order?.price}</p>
              <p className="mt-3 text-gray-500">{description}</p>
            </div>
            <div className="sm:col-span-12 md:col-span-7">
              <dl className="grid grid-cols-1 gap-y-8 border-b border-gray-200 py-8 sm:grid-cols-2 sm:gap-x-6 sm:py-6 md:py-10">
                <div>
                  <dt className="font-medium text-gray-900">
                    {t(i18nKeys.profile.order.detail.deliveryAddress)}
                  </dt>
                  <dd className="mt-3 text-gray-500">
                    <span className="block">{deliveryAddress}</span>
                  </dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-900">
                    {t(i18nKeys.profile.order.detail.shippingUpdates)}
                  </dt>
                  <dd className="mt-3 space-y-3 text-gray-500">
                    <p>{tracking.emails[0] ?? '-'}</p>
                  </dd>
                </div>
              </dl>
              <p className="mt-6 font-medium text-gray-900 md:mt-10">
                {statusTimestamp}
              </p>
              <div className="mt-6">
                <div className="overflow-hidden rounded-full bg-gray-200">
                  <div
                    className="h-2 rounded-full"
                    style={{
                      backgroundColor: statusColorMapping[currentStep.status],
                      width: `calc((${currentStep.progress} * 2 + 1) / 8 * 100%)`,
                    }}
                  />
                </div>
                <div className="mt-6 hidden grid-cols-4 font-medium text-gray-600 sm:grid">
                  {orderSteps.map((step, key) => (
                    <div
                      key={key}
                      style={{
                        color:
                          currentStep.progress >= key
                            ? statusColorMapping[currentStep.status]
                            : undefined,
                      }}
                      className={classNames(
                        key === 0
                          ? 'text-start'
                          : key < orderSteps.length - 1
                            ? 'text-center'
                            : 'text-end',
                      )}
                    >
                      {t(
                        currentStep.progress === key ? currentStep.label : step,
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Billing */}
      <div className="mt-24">
        <div className="rounded-lg bg-gray-50 px-6 py-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-0 lg:py-8">
          <dl className="grid grid-cols-1 gap-6 text-sm sm:grid-cols-2 md:gap-x-8 lg:col-span-5 lg:pl-8">
            <div>
              <dt className="font-medium text-gray-900">
                {t(i18nKeys.profile.order.detail.billingAddress)}
              </dt>
              <dd className="mt-3 text-gray-500">
                {billingAddressLines.map((line, key) => (
                  <span key={key} className="block">
                    {line}
                  </span>
                ))}
              </dd>
            </div>
            <div>
              <dt className="font-medium text-gray-900">
                {t(i18nKeys.profile.order.detail.paymentInformation)}
              </dt>
              <dd className="mt-3 flex gap-3">
                <div>
                  <PaymentCardIcon
                    type={order.transactionDetails.card.type as PaymentCardType}
                    className="h-6 w-auto"
                  />
                </div>
                <div>
                  <p className="text-gray-900">{cardEnding}</p>
                  <p className="text-gray-600">{cardExpiration}</p>
                </div>
              </dd>
            </div>
          </dl>

          <dl className="mt-8 divide-y divide-gray-200 text-sm lg:col-span-7 lg:mt-0 lg:pr-8">
            {displayCosts.map((cost, key) => (
              <div
                key={key}
                className={classNames(
                  'flex items-center justify-between',
                  key > 0 && 'pt-4',
                  key < displayCosts.length - 1 && 'pb-4',
                )}
              >
                <dt
                  className={classNames(
                    'text-gray-600',
                    cost.highlight && 'font-semibold',
                  )}
                >
                  {cost.label}
                </dt>
                <dd
                  className={classNames(
                    'text-gray-900',
                    cost.highlight ? 'font-bold' : 'font-medium ',
                  )}
                >
                  {cost.price}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </>
  )
}
